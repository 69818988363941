import { useContext } from 'react';
import {
  Button,
  CardWrapperWithHeader,
  getStationByKey,
  LoadingSpinner,
  StepsProgress,
} from '@rabbit/elements/shared-components';
import AssignCaseInternalModal from '../../../organisms/case-flow-modals/AssignCaseInternalModal/AssignCaseInternalModal';
import {
  DocumentCheckIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/solid';
import { CaseflowContext } from '../../../../context/CaseflowContext';
import { ConfigContext } from '@rabbit/config/context';
import { CaseflowCaseStatus } from '@rabbit/bizproc/core';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ClaimStatusSectionProps {}

export function ClaimStatusSection(props: ClaimStatusSectionProps) {
  const context = useContext(CaseflowContext);
  const { config } = useContext(ConfigContext);

  const {
    caseState,
    caseFacts,
    setModalSettings,
    setShowModal,
    alterCaseFacts,
    executeAction,
    operatingPersonaSingleKey,
  } = context || {};

  const currentStation = getStationByKey(caseState);

  const modalSettingsOptions = {
    assign_case: {
      kind: 'generic' as const,
      settings: {
        title: 'Assign case',
      },
      children: <AssignCaseInternalModal />,
    },
  };

  const steps = [
    {
      Icon: InformationCircleIcon,
      completed: currentStation.id >= 1 ? true : false,
      title: CaseflowCaseStatus.WAITING_FOR_REVIEW,
      // content: (
      //   <div className="mt-2 text-xs text-gray-400 font-nunito">
      //     18/04/2023
      //     <br />
      //     9:30 am
      //   </div>
      // ),
    },
    {
      Icon: DocumentCheckIcon,
      completed: currentStation.id >= 2 ? true : false,
      title: CaseflowCaseStatus.ASSESSMENT,
      // content: (
      //   <div className="mt-2 text-xs text-gray-400 font-nunito">
      //     19/04/2023
      //     <br />
      //     01:27 pm
      //   </div>
      // ),
    },
    {
      Icon: InformationCircleIcon,
      completed: currentStation.id >= 6 ? true : false,
      title: CaseflowCaseStatus.REPAIR_REPLACE,
      //content: '',
    },
    {
      Icon: DocumentCheckIcon,
      completed: currentStation.id >= 10 ? true : false,
      title: CaseflowCaseStatus.COMPLETE,
      //content: '',
    },
  ];

  if (
    !context ||
    !setModalSettings ||
    !setShowModal ||
    !alterCaseFacts ||
    !executeAction ||
    !operatingPersonaSingleKey
  )
    return <LoadingSpinner size="xs" />;

  return (
    <CardWrapperWithHeader
      title="Claim status"
      headerRight={
        config.CLAIMS.CLAIMS_FLOW.CAN_ASSIGN_REPAIRER.includes(
          operatingPersonaSingleKey
        ) && (
          <div className="flex items-center gap-5">
            <Button
              size="sm"
              kind="primary"
              children={`${
                caseFacts?.delegate_repairer_name !== 'Unassigned'
                  ? 'Assigned to: ' + caseFacts?.delegate_repairer_name
                  : 'Unassigned'
              }`}
              onClick={() => {
                setModalSettings(modalSettingsOptions.assign_case);
                setShowModal(true);
              }}
            />
          </div>
        )
      }
    >
      <div className="px-10">
        <StepsProgress steps={steps} />
      </div>
    </CardWrapperWithHeader>
  );
}

export default ClaimStatusSection;
