import { CaseflowCaseTypes } from '@rabbit/bizproc/core';
import { PersonaTypeSingleLetter } from '@rabbit/data/types';

// Keeping it JSON only here. Generators derived from this (for forms, tables, etc) will have to be their own thing.
// Also I hate gigantic JSON objects all in one file so we import each bit from a separate file.
//
// Please read documentation/config.md for more info

type ClaimsHoldingForms = 'BASE' | 'TYPE1';
type ClaimsTableType = 'SIMPLE' | 'BASIC' | 'ADVANCED';
type ClaimDetailViewType = 'DEFAULT' | 'CAR_DETAILS';
type RestrictedUserPathsType = {
  Warrantor: string[];
  ExternalRepairer: string[];
};

const BaseConfig = {
  NAVIGATION: {
    RESTRICTED: false,
    RESTRICTED_PATHS: [] as string[],
    RESTRICTED_USER_PATHS: {
      Warrantor: [],
      ExternalRepairer: [],
    } as RestrictedUserPathsType,
  },
  DASHBOARD: {},
  CLAIMS: {
    CASEFLOW_TYPE: CaseflowCaseTypes.LITE,
    OPERATING_PERSONAS: [
      PersonaTypeSingleLetter.Repairer,
      PersonaTypeSingleLetter.Warrantor,
    ],
    PROXY_OWNER_PERSONA: PersonaTypeSingleLetter.Warrantor,
    INTERNAL_REPAIRERS_ENABLED: false,
    CLAIMS_FLOW: {
      CAN_ASSIGN_REPAIRER: [] as PersonaTypeSingleLetter[],
      SERIAL_NUMBER: false,
      SERIAL_NUMBER_PROOF: false,
      CREATE_WITH_WARRANTY_INFO: false,
      CREATE_HOLDING_FORM: 'BASE' as ClaimsHoldingForms,
      CLAIMS_TABLE_TYPE: 'TYPE1' as ClaimsTableType,
      NEW_CLAIM: {
        NEW_USER_BTN: true,
        NEW_PRODUCT_BTN: true,
      },
      INVOICE: {
        ENABLED: false,
      },
    },
    CHAT: {
      IS_READONLY: false,
    },
    CLAIM_DETAIL_VIEW: {
      UPDATE_CLAIM_DETAILS: {
        ENABLED: [PersonaTypeSingleLetter.Warrantor],
        CAN_EDIT_CLAIM_STATUS: [PersonaTypeSingleLetter.Warrantor],
      },
      USE_CLAIM_ACTIONS: [] as PersonaTypeSingleLetter[],
      TYPE: 'DEFAULT' as ClaimDetailViewType,
    },
  },
  AUTHENTICATION: {},
  ACCOUNT: {},
  HOLDINGS: {
    NEW_REGISTRATION_FLOW: {
      NEW_USER_BTN: true,
    },
  },
  VENDABLES: {},
  WARRANTIES: {
    MANAGEMENT: {
      SHOW_WARRANTY_OPTIONS_BTN: false,
      CAN_VOID_WARRANTIES: false,
      CAN_EDIT_WARRANTIES: false,
    },
  },
  MARKETING: {},
};

export default BaseConfig;
