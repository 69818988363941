import React, { useState, useEffect, useContext } from 'react';
import {
  ButtonIconVertical,
  CardWrapperWithHeader,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import {
  AssignCaseInternalModal,
  DetermineCoverageModal,
  FinalAssessmentModal,
  ApproveCaseForRepairModal,
  AssignClaimToThirdPartyModal,
  //CloseCaseModal,
  CompleteRepairModal,
  ConfirmItemReceivedModal,
  ContactCustomerModal,
  LogItemReceivedModal,
  LogPostageModal,
  LogRepairCompleteModal,
  LogRepairWorkModal,
  QuoteClaimModal,
  RequestToSendItemModal,
  ReplacePartsOrProductModal,
  LocalRepairModal,
  CloseCaseModal,
} from '../../../organisms/case-flow-modals';
// todo: add these two below to index file above
import CustomerCollectionModal from '../../../organisms/case-flow-modals/CustomerCollectionModal/CustomerCollectionModal';
import RejectClaimModal from '../../../organisms/case-flow-modals/RejectClaimModal/RejectClaimModal';

import { CaseflowContext } from '../../../../context/CaseflowContext';
import { CaseFactsShape } from '../../../../../types';
import { getActionIcon } from '../../../../utils/consts';
import {
  PersonaTypeSingleLetter,
  PossibleActionEntry,
} from '@rabbit/data/types';
import CompleteRepairAndCloseModal from '../../../organisms/case-flow-modals/CompleteRepairAndCloseModal/CompleteRepairAndCloseModal';
import {
  CaseFlowCaseStations,
  CaseflowCaseTypes,
  ExternalRepairInvoiceStatus,
} from '@rabbit/bizproc/core';
import { t } from 'i18next';
import InitiateRepairModal from '@rabbit/sage/components/organisms/case-flow-modals/InitiateRepairModal/InitiateRepairModal';
import MarkRepairAsCompleteModal from '@rabbit/sage/components/organisms/case-flow-modals/MarkRepairCompleteModal/MarkRepairCompleteModal';
import ModalAddEditInvoice from '@rabbit/sage/components/organisms/ModalAddEditInvoice/ModalAddEditInvoice';
import AddEditInvoiceFormRepairer from '../../AddEditInvoiceFormRepairer/AddEditInvoiceFormRepairer';
import { ConfigContext } from '@rabbit/config/context';
import CloseCaseFatbikesModal from '@rabbit/sage/components/organisms/case-flow-modals/CloseCaseFatbikesModal/CloseCaseFatbikesModal';

// TODOS
// Move as many of the moveSpotlight calls to the tenant config as possible
// todo: This is all too Shelta specific and needs a refactor - DC

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ClaimActionsProps {}

export function ClaimActions(props: ClaimActionsProps) {
  const { config } = useContext(ConfigContext);
  const {
    caseFlowCase,
    setShowModal,
    setModalSettings,
    caseState,
    caseActionsStation,
    caseActionsAll,
    executeAction,
    alterCaseFacts,
    caseFacts,
    caseId,
    caseAlterability,
    operatingPersona,
    operatingPersonaSingleKey,
    filterAvailableCFCActions,
    moveSpotlight,
    holdingData,
  } = useContext(CaseflowContext) || {};

  const [availableActions, setAvailableActions] = useState({});

  // console.log('caseState', caseState);
  // console.log('caseFacts', caseFacts);
  // console.log('caseActionsStation', caseActionsStation);
  // console.log('caseActionsAll', caseActionsAll);
  //console.log('caseFlowCase', caseFlowCase);
  // console.log('availableActions', availableActions);

  /* ------------------------- Dirty hack zone starts ------------------------- */
  // Todo get this out of here - DC
  // Get the unsubmitted external repairer invoice
  const external_repairer_invoice = caseFacts?.external_repair_invoices
    ? caseFacts?.external_repair_invoices?.find(
        (invoice) => invoice.status === ExternalRepairInvoiceStatus.UNSUBMITTED
      )
    : undefined;

  /* -------------------------- Dirty hack zone ends -------------------------- */

  useEffect(() => {
    if (caseFacts && filterAvailableCFCActions) {
      handleActionFiltering(
        caseFacts,
        caseState,
        filterAvailableCFCActions,
        setAvailableActions
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    caseActionsStation,
    caseFacts,
    caseFacts?.delegate_repairer_name,
    caseFacts?.preliminary_assessment,
    caseFacts?.goodwill_override_initial,
    caseFacts?.final_assessment,
    caseFacts?.local_repairer,
    caseState,
  ]);

  if (
    !caseFlowCase ||
    !setShowModal ||
    !setModalSettings ||
    !caseState ||
    !caseActionsStation ||
    !caseActionsAll ||
    !executeAction ||
    !alterCaseFacts ||
    !caseFacts ||
    !caseId ||
    !caseAlterability ||
    !operatingPersona ||
    !moveSpotlight ||
    !holdingData
  )
    return (
      <CardWrapperWithHeader title="Claim actions">
        <LoadingSpinner size="xs" extraClasses="min-h-[128px]" />
      </CardWrapperWithHeader>
    );

  // Turn the actions into an array for mapping and add relevant data to them
  const actionsArray = Object.entries(
    caseState === CaseFlowCaseStations.INITIALLY_ASSESSED ||
      caseState === CaseFlowCaseStations.ASSIGNED ||
      caseState === CaseFlowCaseStations.REPAIR_INVOICE_ASSESSMENT ||
      caseState === CaseFlowCaseStations.PRELIMINARY_ASSESSMENT ||
      caseState === CaseFlowCaseStations.FINAL_ASSESSMENT ||
      caseState === CaseFlowCaseStations.REPAIR ||
      caseState === CaseFlowCaseStations.CONFIRM_QUOTATION_RESPONSE
      ? availableActions
      : caseActionsStation
  ).map(([key, value]) => {
    return {
      ...(value as PossibleActionEntry),
      Icon: getActionIcon(key),
    };
  });

  const modalSettingOptions = {
    assign_case: {
      kind: 'generic' as const,
      settings: {
        title: 'Assign case',
      },
      children: <AssignCaseInternalModal />,
    },
    preliminary_assessment: {
      kind: 'generic' as const,
      settings: {
        title: 'Preliminary assessment',
      },
      children: <DetermineCoverageModal />,
    },
    final_assessment: {
      kind: 'generic' as const,
      settings: {
        title: 'Final assessment',
      },
      children: <FinalAssessmentModal />,
    },
    request_customer_send_item: {
      kind: 'generic' as const,
      settings: {
        title: 'Request customer to send item',
        primaryButtonText: 'Send request to customer',
        secondaryButtonText: 'Cancel',
      },
      children: <RequestToSendItemModal />,
    },
    quote_customer: {
      kind: 'generic' as const,
      settings: {
        title: 'Quote customer',
        primaryButtonText: 'Send quote',
        secondaryButtonText: 'Cancel',
      },
      children: <QuoteClaimModal />,
    },
    assign_to_authorised_repairer: {
      kind: 'generic' as const,
      settings: {
        title: 'Assign to authorised repairer',
        primaryButtonText: 'Assign to a repairer',
        secondaryButtonText: 'Cancel',
      },
      children: <AssignClaimToThirdPartyModal />,
    },
    replace_part_or_product: {
      kind: 'generic' as const,
      settings: {
        title: 'Replace parts or product',
        primaryButtonText: 'Confirm',
        secondaryButtonText: 'Cancel',
      },
      children: (
        <ReplacePartsOrProductModal
          repair_work_registry={caseFacts?.repair_work_registry ?? []}
        />
      ),
    },
    log_item_received: {
      kind: 'generic' as const,
      settings: {
        title: 'Log item as received',
        primaryButtonText: 'Notify customer',
        secondaryButtonText: 'Cancel',
      },
      children: <LogItemReceivedModal />,
    },
    log_authorised_repairer_repair_complete: {
      kind: 'generic' as const,
      settings: {
        title: 'Log repair as complete',
        primaryButtonText: 'Log repair as complete',
        secondaryButtonText: 'Cancel',
      },
      children: <LogRepairCompleteModal />,
    },
    confirm_customer_received_item: {
      kind: 'generic' as const,
      settings: {
        title: 'Confirm customer received item',
        primaryButtonText: 'Confirm and close case',
        secondaryButtonText: 'Cancel',
      },
      children: <ConfirmItemReceivedModal />,
    },
    log_postage: {
      kind: 'generic' as const,
      settings: {
        title: 'Log postage',
        primaryButtonText: 'Log postage',
        secondaryButtonText: 'Cancel',
      },
      children: (
        <LogPostageModal postage_registry={caseFacts?.postage_registry ?? []} />
      ),
    },
    message_customer: {
      kind: 'generic' as const,
      settings: {
        primaryButtonText: 'Notify customer',
        secondaryButtonText: 'Cancel',
      },
      children: <ContactCustomerModal messageCustomer />,
    },
    contact_customer_generic: {
      kind: 'generic' as const,
      settings: {
        primaryButtonText: 'Notify customer',
        secondaryButtonText: 'Cancel',
      },
      children: <ContactCustomerModal />,
    },
    log_repair_work: {
      kind: 'generic' as const,
      settings: {
        title: 'Log repair work',
        primaryButtonText: 'Notify customer',
        secondaryButtonText: 'Cancel',
      },
      children: (
        <LogRepairWorkModal
        // todo: these props can probably be reached from the context, no? -dc
        // repair_work_registry={caseFacts?.repair_work_registry ?? []}
        // claim_outcome={caseFacts?.claim_outcome ?? ''}
        />
      ),
    },
    approve_case_for_repair: {
      kind: 'generic' as const,
      settings: {
        title: 'Approve case for repair',
        primaryButtonText: 'Approve for repair',
        secondaryButtonText: 'Cancel',
      },
      children: <ApproveCaseForRepairModal />,
    },
    reject_claim: {
      kind: 'generic' as const,
      settings: {
        primaryButtonText: 'Notify customer',
        secondaryButtonText: 'Cancel',
      },
      children: <RejectClaimModal />,
    },
    customer_collection: {
      kind: 'generic' as const,
      settings: {
        primaryButtonText: 'Notify customer',
        secondaryButtonText: 'Cancel',
      },
      children: <CustomerCollectionModal />,
    },
    assign_to_local_repairer: {
      kind: 'generic' as const,
      settings: {
        primaryButtonText: 'Log repair work',
        secondaryButtonText: 'Cancel',
      },
      children: <LocalRepairModal />,
    },
    complete_repair: {
      kind: 'generic' as const,
      settings: {
        title: 'Contact customer',
        primaryButtonText: 'Notify customer',
        secondaryButtonText: 'Cancel',
      },
      children: <CompleteRepairModal />,
    },
    // Only if case is assigned to local repairer, i.e. local_repairer fact exists
    complete_local_repair_and_close_case: {
      kind: 'generic' as const,
      settings: {
        primaryButtonText: 'Complete repair and close case',
        secondaryButtonText: 'Cancel',
      },
      children: <CompleteRepairAndCloseModal />,
    },
    close_case: {
      kind: 'generic' as const,
      settings: {
        primaryButtonText: 'Close case',
        secondaryButtonText: 'Cancel',
      },
      // todo: improve this, we're trying to get rid of this kind of conditions - DC
      children:
        config.CLAIMS.CASEFLOW_TYPE === CaseflowCaseTypes.FATBIKES ? (
          <CloseCaseFatbikesModal />
        ) : (
          <CloseCaseModal />
        ),
    },
    /* ----------------------------- Fatbikes modals ---------------------------- */
    initiate_repair: {
      kind: 'generic' as const,
      settings: {
        primaryButtonText: t('Initiate repair'),
        secondaryButtonText: t('Cancel'),
      },
      children: <InitiateRepairModal />,
    },
    // todo there is already a similarly named modal above, consider merging in the future - DC
    mark_repair_complete: {
      kind: 'generic' as const,
      settings: {
        primaryButtonText: t('Mark repair as complete'),
        secondaryButtonText: t('Cancel'),
      },
      children: <MarkRepairAsCompleteModal />,
    },
    submit_invoice: {
      kind: 'generic' as const,
      settings: {
        primaryButtonText: t('Submit invoice'),
        secondaryButtonText: t('Cancel'),
      },
      children: (
        <ModalAddEditInvoice
          data={external_repairer_invoice}
          mode="submit"
          handleClose={() => setShowModal(false)}
        />
      ),
    },
  };

  if (!operatingPersona)
    return (
      <CardWrapperWithHeader title="Claim actions">
        <LoadingSpinner size="xs" />
      </CardWrapperWithHeader>
    );

  return (
    <CardWrapperWithHeader title="Claim actions">
      <div className="flex gap-[40px] overflow-auto pb-4 lg:pb-2">
        {caseAlterability === 'AwaitingUpdate' ? (
          <div className="mx-auto items-center">
            <LoadingSpinner size={'xs'} />
          </div>
        ) : (
          actionsArray.map((action) => {
            const key = action.key;
            return (
              <ButtonIconVertical
                key={key}
                kind={'claim-actions'}
                label={action.label}
                Icon={action.Icon}
                onClick={() => {
                  setModalSettings(
                    modalSettingOptions[key as keyof typeof modalSettingOptions]
                  );
                  setShowModal(true);
                }}
              />
            );
          })
        )}

        {actionsArray.length === 0 && (
          <p className="font-nunito">
            {t('There are no available actions at the moment for this Claim.')}
          </p>
        )}
      </div>
    </CardWrapperWithHeader>
  );
}

export default ClaimActions;

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

const handleActionFiltering = (
  caseFacts: Partial<CaseFactsShape>,
  caseState: string,
  filterAvailableCFCActions: (
    station: string,
    condition: string
  ) => { [key: string]: any } | null, // todo type any
  setAvailableActions: React.Dispatch<React.SetStateAction<any>> // todo type
) => {
  const {
    delegate_repairer_name,
    preliminary_assessment,
    goodwill_warranty_initial,
    goodwill_warranty_final,
    final_assessment,
    local_repairer,
    external_repair_invoices,
  } = caseFacts;

  let filteredActions: { [key: string]: any } | null = {};

  const filter = (station: string, condition: string) => {
    filteredActions = filterAvailableCFCActions(station, condition);
  };

  const getAssessment = (stage: 'initial' | 'final') => {
    if (stage === 'initial') {
      return goodwill_warranty_initial || preliminary_assessment;
    } else {
      return goodwill_warranty_final || final_assessment;
    }
  };
  // Station === Preliminary assessment
  if (caseState === 'preliminary_assessment') {
    filter(
      'preliminary_assessment',
      delegate_repairer_name === 'Unassigned'
        ? 'delegate_unassigned'
        : 'delegate_assigned'
    );
  }
  // Station === Initially assessed
  if (caseState === 'initially_assessed') {
    if (getAssessment('initial') === 'Fully covered') {
      filter('initially_assessed', 'fully_covered');
    }
    if (
      getAssessment('initial') === 'Partially covered' ||
      getAssessment('initial') === 'Not covered'
    ) {
      filter('initially_assessed', 'partially_covered_or_not_covered');
    }
    if (getAssessment('initial') === 'Further inspection required') {
      filter('initially_assessed', 'further_inspection');
    }
  }
  // Station === Final assessment
  if (caseState === 'final_assessment') {
    if (!final_assessment) {
      filter('final_assessment', 'no_fa');
    } else if (getAssessment('final') === 'Fully covered') {
      filter('final_assessment', 'fa_fully_covered');
    } else if (getAssessment('final') === 'Partially covered') {
      filter('final_assessment', 'fa_partially_covered');
    } else if (getAssessment('final') === 'Not covered') {
      filter('final_assessment', 'fa_not_covered');
    } else {
      filter('final_assessment', 'fa_other_outcomes');
    }
  }

  // Station === Repair
  if (caseState === 'repair') {
    filter(
      'repair',
      local_repairer ? 'has_local_repairer' : 'no_local_repairer'
    );
  }

  // Station === Confirm Quotation Response
  if (caseState === 'confirm_quotation_response') {
    if (
      getAssessment('final') === 'Partially covered' ||
      getAssessment('final') === 'Not covered'
    ) {
      filter('confirm_quotation_response', 'cqr_partial_or_none');
    } else {
      filter('confirm_quotation_response', 'cqr_other');
    }
  }
  // Station === Assigned
  if (caseState === CaseFlowCaseStations.ASSIGNED) {
    const external_repairer_invoice = caseFacts?.external_repair_invoices
      ? caseFacts?.external_repair_invoices?.find(
          (invoice) =>
            invoice.status === ExternalRepairInvoiceStatus.UNSUBMITTED
        )
      : undefined;

    filter(
      CaseFlowCaseStations.ASSIGNED,
      external_repairer_invoice ? 'invoice_uploaded' : 'no_invoice_uploaded'
    );
  }

  setAvailableActions(filteredActions);
};
