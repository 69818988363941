import {
  ArrowTopRightOnSquareIcon,
  Cog8ToothIcon,
  EnvelopeIcon,
} from '@heroicons/react/24/solid';
import {
  formatAddress,
  formatUnixTime,
  getCurrencyFormat,
  getStationByKey,
  toTitleCase,
} from '../../utils/helpers';
import { useGetDocs, useSendEmail } from '@rabbit/bizproc/react';
import { useEffect, useState } from 'react';
import { Button } from '../../atoms/buttons/Button/Button';
import {
  ButtonIcon,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import { TimelineUpdateShape } from '../../organisms/TimelineUpdates/TimelineUpdates';
import UploadedFileCard from '../UploadedFileCard/UploadedFileCard';
import {
  AppInfoShape,
  GazetteEmailParams,
  PersonaTypeSingleLetter,
  UserUploadedDocument,
} from '@rabbit/data/types';
import Skeleton from 'react-loading-skeleton';
import { CaseFlowFacts } from '@rabbit/data/types';
import { toast } from 'react-toastify';
import {
  CFCF_ExternalRepairInvoice,
  CFCF_FileUpdateEvent,
  CFCF_FileUpdateEventAction,
  CFCF_FileUpdateEventCategory,
  ExternalRepairInvoiceStatus,
} from '@rabbit/bizproc/core';

// export interface MessageInterface {
//   a: string;
//   t: number; //Might be changed
//   newState: string;
//   oldState: string;
//   message?: string;
//   k: number; //number of 1 to 7
//   kv?: any; // add interface later on
// }

const styles = {
  boxGray:
    'my-3 flex flex-col gap-5 rounded-md border border-gray-200 bg-gray-50 p-3 text-base text-gray-500',
  plain: 'my-3 text-base text-gray-500',
};

interface CaseFlowHandlersShape {
  executeAction: (
    action: string,
    params?: { [key: string]: any }
  ) => Promise<void>;
  caseFacts: CaseFlowFacts; // todo
  alterCaseFacts: (facts: { [key: string]: any }) => Promise<void>;
  caseActionsStation?: any; // Todo
  alterCaseSpotlight: (opt: { clear: boolean }) => void;
}

export interface MessageWithLabelAndTagProps {
  data: TimelineUpdateShape;
  store: TimelineUpdateShape[];
  customer: boolean;
  appInfo: AppInfoShape;
  logPostageAction?: () => void;
  cfHandlers?: CaseFlowHandlersShape;
}

export function MessageWithLabelAndTag({
  data,
  store,
  customer,
  appInfo,
  logPostageAction,
  cfHandlers,
}: MessageWithLabelAndTagProps) {
  const { getConsumerData, getRepairerData, getFaultData, getWarrantorData } =
    useGetDocs();
  const [name, setName] = useState('');
  const [repairerName, setRepairerName] = useState<string>('');
  const [internalCaseFilesMsg, setInternalCaseFilesMsg] = useState<any[]>([]); //TODO
  const [faultLabels, setFaultLabels] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showMarkAsSentConfirmation, setShowMarkAsSentConfirmation] =
    useState(false);
  const [showMailModal, setShowMailModal] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [loadedEmailBody, setLoadedEmailBody] = useState('');
  const { getEmailTemplate } = useSendEmail();
  const { caseFacts, executeAction, alterCaseFacts, alterCaseSpotlight } =
    cfHandlers || {};

  const checkIfFileExistsOnCaseFacts = (url: string) => {
    if (
      !caseFacts?.consumer_claim_evidence &&
      !caseFacts?.consumer_proof_of_purchase &&
      !caseFacts?.item_shipping_label
    )
      return false;
    return (
      caseFacts?.consumer_claim_evidence?.some(
        (file: any) => file.url === url
      ) ||
      caseFacts?.consumer_proof_of_purchase?.some(
        (file: any) => file.url === url
      ) ||
      caseFacts?.item_shipping_label?.some((file: any) => file.url === url)
    );
  };

  useEffect(() => {
    const getPersonaData = async (id: string) => {
      if (!id) return;
      if (id[0] === PersonaTypeSingleLetter.Consumer) {
        const consumerData = await getConsumerData([id]);
        return consumerData;
      }
      if (id[0] === PersonaTypeSingleLetter.Repairer) {
        const repairerData = await getRepairerData([id]);
        return repairerData;
      }
      if (id[0] === PersonaTypeSingleLetter.Warrantor) {
        const warrantorData = await getWarrantorData([id]);
        return warrantorData;
      }
    };
    void (async () => {
      const res = await getPersonaData(data.a);
      const user: any = res?.[0];
      if (user) {
        //show business name instead of user.name in the Customer/Olive app
        if (customer && user.docid[0] === PersonaTypeSingleLetter.Repairer) {
          setName(appInfo.name);
        } else {
          setName(user.fullname ?? user.name);
        }
      }
      if (data?.kv?.repairer_id) {
        const repairerData = await getPersonaData(data.kv.repairer_id);
        const repairer: any = repairerData?.[0];
        if (repairer) setRepairerName(repairer.fullname ?? repairer.name);
      }
      if (
        data?.kv?.repair_work_registry &&
        data.kv.repair_work_registry[0]?.delegate_repairer_name
      ) {
        setRepairerName(
          data.kv.repair_work_registry[0]?.delegate_repairer_name
        );
      }
      if (data?.kv?.holding_faults) {
        const faultData = await getFaultData(data.kv.holding_faults);
        if (faultData.length > 0) {
          const labels: string[] = faultData.map((fault: any) => fault.title);
          setFaultLabels(labels);
        }
      }
    })()
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  }, [data]);

  //TODO: Pass in the persona id so you can have warrantor, repairer, consumer as fallbacks if need be
  const getName = () => {
    //fallback
    return name ? name : 'The repairer';
  };

  const getEmail = async (data: GazetteEmailParams) => {
    setEmailLoading(true);
    const mergeVars = Object.keys(data.substitutions).map((i) => ({
      name: i,
      content: data.substitutions[i],
    }));
    const mainTemplate = appInfo.email_main_template;
    const main = await getEmailTemplate(
      mainTemplate,
      data.template as any,
      mergeVars
    );
    setLoadedEmailBody(main.data as any);
    setEmailLoading(false);
  };

  useEffect(() => {
    async function imageExists(imgUrl: string) {
      if (!imgUrl) {
        return false;
      }
      return new Promise((res) => {
        const image = new Image();
        image.onload = () => res(true);
        image.onerror = () => res(false);
        image.src = imgUrl;
      });
    }

    function isImageFile(filename: string) {
      const imageFileExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
      const extension = filename.slice(
        ((filename.lastIndexOf('.') - 1) >>> 0) + 2
      );
      return imageFileExtensions.includes(extension);
    }

    async function getInternalCaseFilesArr(files: UserUploadedDocument[]) {
      if (files.length === 0) return [<div></div>];

      const promises = files.map(async (file, index) => {
        const exists = await imageExists(file.url);

        if (exists || !isImageFile(file.ogFilename)) {
          return (
            <div key={file.url} className="flex-grow">
              <UploadedFileCard data={file} status="preview" />
            </div>
          );
        }
        return (
          <div key={index} className="flex-grow">
            <p>This file has been deleted or is not an image.</p>
          </div>
        );
      });
      const results = await Promise.all(promises);

      return results;
    }

    if (data.kv?.internal_case_files?.length) {
      (async () => {
        await getInternalCaseFilesArr(data.kv?.internal_case_files).then(
          (res) => setInternalCaseFilesMsg(res)
        );
      })().catch((err) => console.log(err));
    }
  }, [data.kv?.internal_case_files]);

  const renderRowWithLabel = (label: string | null, comment: any) => {
    return (
      <div className="">
        {label && (
          <div>
            <strong>{label}:</strong>
          </div>
        )}
        {typeof comment === 'string' || comment instanceof String ? (
          <div dangerouslySetInnerHTML={{ __html: comment }}></div>
        ) : comment.comment ? (
          <div dangerouslySetInnerHTML={{ __html: comment.comment }}></div>
        ) : (
          <div>{comment}</div>
        )}
      </div>
    );
  };

  const renderStringArrWithLabel = (
    label: string | null,
    stringArr: string[]
  ) => {
    return (
      <div className="">
        {label && (
          <div>
            <strong>{label}:</strong>
          </div>
        )}
        {stringArr.map((item, index) => {
          return <p key={index}>{item}</p>;
        })}
      </div>
    );
  };

  const renderUploadUpdate = (
    data: any, // todo: fix type
    action: CFCF_FileUpdateEventAction,
    label: string,
    files: UserUploadedDocument[]
  ) => {
    const renderFileCard = (item: UserUploadedDocument) => {
      if (
        checkIfFileExistsOnCaseFacts(item.url) ||
        action === CFCF_FileUpdateEventAction.DELETION
      ) {
        return (
          <div className="flex flex-col gap-3">
            <div>
              {action === CFCF_FileUpdateEventAction.UPLOAD &&
                `${getName()} uploaded ${label}`}
              {action === CFCF_FileUpdateEventAction.DELETION &&
                `${getName()} deleted ${label}`}
            </div>
            <div key={item.url} className="flex-grow">
              <UploadedFileCard
                data={item}
                status={
                  action === CFCF_FileUpdateEventAction.DELETION
                    ? 'deleted'
                    : 'preview'
                }
              />
            </div>
          </div>
        );
      }
    };

    return (
      <div>
        <div className="mb-4 flex">
          <div className="w-full text-base text-gray-500">
            <div className="flex flex-col gap-3">
              {files &&
                (Array.isArray(files) ? (
                  files.length > 0 &&
                  files.map((item: UserUploadedDocument, index: number) => {
                    return renderFileCard(item);
                  })
                ) : (
                  <div className="flex-grow">{renderFileCard(files)}</div>
                ))}
              {data?.kv?.comment_to_customer &&
                renderRowWithLabel(
                  'Comment to customer',
                  data?.kv?.comment_to_customer
                )}
              {data?.kv?.internal_comment &&
                renderRowWithLabel(
                  'Internal comment',
                  data?.kv?.internal_comment
                )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderInternalCaseFilesUpdate = (
    data: any, // todo: fix type
    label: string
  ) => {
    return (
      <div>
        <div className="flex">
          <div className="w-full">
            <div className="my-3 w-full text-base text-gray-500">
              {getName()} uploaded {label}
              <div className="mt-4 flex flex-col gap-3">
                {internalCaseFilesMsg?.length > 0 &&
                  internalCaseFilesMsg?.map((item: any) => {
                    return item;
                  })}

                {data?.kv?.comment_to_customer ||
                  (data?.kv?.internal_comment && (
                    <div className={styles.boxGray}>
                      {data?.kv?.comment_to_customer &&
                        renderRowWithLabel(
                          'Comment to customer',
                          data?.kv?.comment_to_customer
                        )}
                      {data?.kv?.internal_comment &&
                        renderRowWithLabel(
                          'Internal comment',
                          data?.kv?.internal_comment
                        )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderNewState = (
    data: any // todo: fix type
  ) => {
    return (
      <div>
        {data.oldState && data.newState && data.newState !== 'initiate' && (
          <div className={styles.boxGray}>
            <div>
              {getName()} moved claim from{' '}
              <strong>
                {data.oldState === '_birth'
                  ? 'Created'
                  : getStationByKey(data.oldState).label}
              </strong>{' '}
              to <strong>{getStationByKey(data.newState).label}</strong>.
            </div>
            {data?.kv?.comment_to_customer &&
              renderRowWithLabel(
                'Comment to customer',
                data?.kv?.comment_to_customer
              )}
            {data?.kv?.internal_comment &&
              renderRowWithLabel(
                'Internal comment',
                data?.kv?.internal_comment
              )}
          </div>
        )}
      </div>
    );
  };

  const renderQuoteCustomerForClaim = (data: any) => {
    const getFinalAssessement = () => {
      return (
        <strong>
          {data.kv?.goodwill_override_final && data.kv?.goodwill_warranty_final
            ? data.kv?.goodwill_warranty_final
            : data.kv?.final_assessment}
          {data.kv?.goodwill_override_final && ' (Goodwill)'}
        </strong>
      );
    };
    const handleAcceptQuote = async () => {
      if (!alterCaseFacts || !alterCaseSpotlight) return;
      const factsToAlter = {
        cost_to_repair_accepted: true,
      };
      await alterCaseFacts(factsToAlter);
      alterCaseSpotlight({ clear: true });
      toast.success('Quote accepted');
    };

    const handleDeclineQuote = async () => {
      if (!alterCaseFacts || !alterCaseSpotlight) return;
      const factsToAlter = {
        cost_to_repair_accepted: false,
      };
      await alterCaseFacts(factsToAlter);
      alterCaseSpotlight({ clear: true });
      toast.success('Quote declined');
    };

    // find same messages as this
    const same_message = store.filter((record) => record.kv?.cost_to_repair);
    // check if there are older messages
    const outdated =
      same_message.length > 1 &&
      same_message.filter((record) => new Date(record.t) > new Date(data?.t))
        .length > 0;
    // check if there is an older acceptance
    const filterAcceptance = store.filter(
      (record) => record.kv?.cost_to_repair_accepted !== undefined
    );
    const answered =
      filterAcceptance.length > 0 &&
      filterAcceptance.filter((record) => record.t > data?.t).length > 0;

    return (
      <div>
        <div className="flex">
          <div className="w-full">
            {customer ? (
              <div className="mt-4 flex w-full flex-col gap-1 text-base text-gray-500">
                <div>
                  {getName()} has assessed your claim as {getFinalAssessement()}{' '}
                  and has provided a quote.
                  {data?.kv?.cost_to_repair && (
                    <strong className="ml-2">
                      {getCurrencyFormat(
                        data?.kv?.cost_to_repair.amount,
                        data?.kv?.cost_to_repair.currency
                      ) ?? '-'}
                    </strong>
                  )}
                </div>
                <div className={styles.boxGray}>
                  {data?.kv?.comment_to_customer &&
                    renderRowWithLabel(
                      'Comment',
                      data?.kv?.comment_to_customer
                    )}
                </div>
                {outdated && 'Requote issued!'}
                {!answered && !outdated && (
                  <div className="mb-4 flex gap-4">
                    <Button kind="primary" onClick={handleAcceptQuote}>
                      Accept quote
                    </Button>
                    <Button kind="outline" onClick={handleDeclineQuote}>
                      Decline quote
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <div className="my-3 w-full text-base text-gray-500">
                <div className={styles.boxGray}>
                  <div>
                    User {getName()} quoted the customer, <br />
                    Quote value:{' '}
                    {getCurrencyFormat(
                      data?.kv?.cost_to_repair.amount,
                      data?.kv?.cost_to_repair.currency
                    ) ?? '-'}
                    .
                  </div>
                  {data?.kv?.internal_comment &&
                    renderRowWithLabel(
                      'Internal comment',
                      data?.kv?.internal_comment
                    )}
                  {data?.kv?.comment_to_customer &&
                    renderRowWithLabel(
                      'Comment to customer',
                      data?.kv?.comment_to_customer
                    )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderQuoteCostAcceptance = (data: any) => {
    return (
      <div className="text-base text-gray-500">
        {data?.kv?.cost_to_repair_accepted
          ? 'Quote accepted'
          : 'Quote rejected'}
      </div>
    );
  };

  const renderEmailSent = (data: any) => {
    return (
      <div className="flex flex-col">
        <div className="my-auto mr-2 text-base text-gray-500">
          {customer ? 'Email sent to you' : 'Email sent to customer'}
        </div>
        <div className={styles.boxGray + ' gap-0 p-4 text-gray-900'}>
          <div className="grid grid-cols-[1fr_auto] gap-6">
            <div className="flex flex-col">
              <div className="mb-2 flex items-center gap-2">
                <EnvelopeIcon className="h-4" />
                <strong>
                  {data?.substitutions.business_name || appInfo.name}
                </strong>
              </div>
              {data?.subject}
            </div>
            <div className="flex-cols flex items-center">
              <ButtonIcon
                label={'Open'}
                onClick={() => {
                  void getEmail(data);
                  setShowMailModal(true);
                }}
                iconLeft={true}
                Icon={ArrowTopRightOnSquareIcon}
              />
            </div>
          </div>
        </div>
        {showMailModal && (
          <Modal
            kind="generic"
            className="w-full max-w-[720px]"
            settings={{
              title: data?.subject,
              handleClose: () => setShowMailModal(false),
              loading: emailLoading,
            }}
          >
            {emailLoading && <LoadingSpinner size="sm" extraClasses="p-4" />}
            {!emailLoading && (
              <>
                <div
                  className="p-10"
                  dangerouslySetInnerHTML={{ __html: loadedEmailBody }}
                ></div>
                <div className="flex justify-end gap-4 p-5 pb-0">
                  <Button
                    kind="primary"
                    children="Close"
                    type="button"
                    className="flex-grow-0"
                    onClick={() => setShowMailModal(false)}
                  />
                </div>
              </>
            )}
          </Modal>
        )}
      </div>
    );
  };

  const renderLogRepairAsCompleted = (data: any) => {
    return (
      <div>
        <div className="flex">
          <div className="w-full">
            {customer ? (
              <div className="my-3 w-full text-base text-gray-500">
                <div className={styles.boxGray}>
                  <div>
                    {getName()} has logged that this claim is complete and
                    closed.
                  </div>
                  {data?.kv?.comment_to_customer &&
                    renderRowWithLabel(
                      'Comment',
                      data?.kv?.comment_to_customer
                    )}
                </div>
              </div>
            ) : (
              <div className="my-3 w-full text-base text-gray-500">
                <div className={styles.boxGray}>
                  <div>
                    User {getName()} marked the repair by the authorised
                    repairer as complete and closed the case.
                    {data?.kv?.cost_to_repair && (
                      <div>
                        Repair cost:{' '}
                        {getCurrencyFormat(
                          data?.kv?.cost_to_repair.amount,
                          data?.kv?.cost_to_repair.currency
                        ) ?? '-'}
                        .
                      </div>
                    )}
                  </div>
                  {data?.kv?.internal_comment &&
                    renderRowWithLabel(
                      'Internal comment',
                      data?.kv?.internal_comment
                    )}
                  {data?.kv?.comment_to_customer &&
                    renderRowWithLabel(
                      'Comment to customer',
                      data?.kv?.comment_to_customer
                    )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  //TODO SEND PARTS
  const getCommentAction = (data: any) => {
    if (data?.kv?.send_parts) {
      return 'requested Customer to send parts';
    } else {
      return customer ? 'wrote a comment:' : 'messaged the customer:';
    }
  };

  const renderComment = (data: any) => {
    return (
      <div>
        <div className="flex">
          <div className="w-full">
            <div className="my-3 w-full text-base text-gray-500">
              <div className={styles.boxGray}>
                {/* {getName() + ' ' + getCommentAction(data)} */}
                {data?.kv?.comment_to_customer &&
                  renderRowWithLabel(
                    `${
                      getName() +
                      ' ' +
                      (customer ? 'wrote a comment' : 'messaged the customer')
                    }`,
                    data?.kv?.comment_to_customer
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAssessment = (
    data: any // todo: fix type
  ) => {
    return (
      <div>
        <div className="flex">
          {data.kv?.preliminary_assessment && (
            <>
              <div className="my-auto mr-2 text-base text-gray-500">
                Assessement updated to{' '}
              </div>
              <div className="bg-primary-100 flex h-[30px] shrink-0 gap-1 rounded-md px-2 py-0.5 text-center font-medium">
                <Cog8ToothIcon className="text-primary-800 my-auto h-[11px] w-[11px]" />
                <span className="text-primary-800 my-auto text-sm">
                  {data.kv?.goodwill_override_initial &&
                  data.kv?.goodwill_warranty_initial
                    ? data.kv?.goodwill_warranty_initial
                    : data.kv?.preliminary_assessment}
                </span>
              </div>
            </>
          )}
        </div>
        {data.kv?.preliminary_assessment && (
          <div className={styles.boxGray}>
            {getName()} updated Assessement to{' '}
            {data.kv?.goodwill_override_initial &&
            data.kv?.goodwill_warranty_initial
              ? data.kv?.goodwill_warranty_initial
              : data.kv?.preliminary_assessment}
            {data.kv?.goodwill_override_initial && ' (Goodwill)'}
          </div>
        )}
      </div>
    );
  };

  const renderPreliminary = (
    data: any // todo: fix type
  ) => {
    return (
      <div>
        {customer ? (
          <div className={styles.boxGray}>
            Your claim is waiting for review.
          </div>
        ) : (
          <div className={styles.boxGray}>Claim is waiting for review.</div>
        )}
      </div>
    );
  };

  const renderDelegate = (
    data: any // todo: fix type
  ) => {
    return (
      <div>
        <div className={styles.boxGray}>
          <div>
            Claim assigned to <b>{data.kv?.delegate_repairer_name}</b>.
          </div>
          {data?.kv?.internal_comment &&
            renderRowWithLabel('Internal comment', data?.kv?.internal_comment)}
        </div>
      </div>
    );
  };

  const renderInternalComment = (data: any) => {
    return (
      <div>
        <div className="flex">
          <div className="w-full">
            <div className="my-3 w-full text-base text-gray-500">
              {getName() + ' wrote a comment.'}
              <div className={styles.boxGray}>
                {data?.kv?.comment_to_customer &&
                  renderRowWithLabel(
                    'Comment to customer',
                    data?.kv?.comment_to_customer
                  )}
                {data?.kv?.internal_comment &&
                  renderRowWithLabel(
                    'Internal comment',
                    data?.kv?.internal_comment
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderPreliminaryCompleted = (
    data: any // todo: fix type
  ) => {
    return (
      <div>
        <div className={styles.boxGray}>
          {!customer ? (
            <>
              <div>
                Claim preliminary assessment:{' '}
                <strong>
                  {data.kv?.goodwill_override_initial &&
                  data.kv?.goodwill_warranty_initial
                    ? data.kv?.goodwill_warranty_initial
                    : data.kv?.preliminary_assessment}
                  {data.kv?.goodwill_override_initial && ' (Goodwill)'}
                </strong>{' '}
                by {getName()}.
              </div>
              {data?.kv?.holding_faults &&
                faultLabels &&
                renderStringArrWithLabel('Faults list', faultLabels)}
              {data?.kv?.internal_comment &&
                renderRowWithLabel(
                  'Internal comment',
                  data?.kv?.internal_comment
                )}
              {data?.kv?.comment_to_customer &&
                renderRowWithLabel(
                  'Comment to customer',
                  data?.kv?.comment_to_customer
                )}
            </>
          ) : (
            <div>
              {getName()} has assessed your claim as{' '}
              <strong>
                {data.kv?.goodwill_override_initial &&
                data.kv?.goodwill_warranty_initial
                  ? data.kv?.goodwill_warranty_initial
                  : data.kv?.preliminary_assessment}
                {data.kv?.goodwill_override_initial && ' (Goodwill)'}
              </strong>
              .
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderFinalAssessementCompleted = (
    data: any // todo: fix type
  ) => {
    const getFinalAssessement = () => {
      return (
        <strong>
          {data.kv?.goodwill_override_final && data.kv?.goodwill_warranty_final
            ? data.kv?.goodwill_warranty_final
            : data.kv?.final_assessment}
          {data.kv?.goodwill_override_final && ' (Goodwill)'}
        </strong>
      );
    };

    return (
      <div>
        <div className={styles.boxGray}>
          {!customer ? (
            <>
              <div>
                Final assessment completed by {getName()}.<br />
                {/* //TODO Gazzete missing the following data */}
                {/* Preliminary assessment: {data?.kv?.preliminary_assessment}<br/> */}
                Final assessment: {getFinalAssessement()}.
              </div>
              {data?.kv?.internal_comment &&
                renderRowWithLabel(
                  'Internal comment',
                  data?.kv?.internal_comment
                )}
              {data?.kv?.comment_to_customer &&
                renderRowWithLabel(
                  'Comment to customer',
                  data?.kv?.comment_to_customer
                )}
            </>
          ) : (
            <>
              <div>
                {getName()} has updated your claim assessment to{' '}
                {getFinalAssessement()}.
              </div>
              {data?.kv?.comment_to_customer &&
                renderRowWithLabel('Comment', data?.kv?.comment_to_customer)}
            </>
          )}
        </div>
      </div>
    );
  };

  const renderRequestItemFromCustomer = (
    data: any // todo: fix type
  ) => {
    return (
      <div>
        {customer ? (
          <>
            {caseFacts?.consumer_marked_item_sent && (
              <div className={styles.boxGray}>You have marked item as sent</div>
            )}
            <div className={styles.boxGray}>
              <div>
                {getName()} has assessed your claim provisionally and is
                requesting you send the item in for repair.
              </div>
              {data?.kv?.comment_to_customer &&
                renderRowWithLabel('Comment', data?.kv?.comment_to_customer)}
            </div>
            {caseFacts?.item_shipping_label && (
              <div className="my-3">
                <UploadedFileCard
                  data={caseFacts.item_shipping_label}
                  status="preview"
                />
              </div>
            )}
            <div className={styles.plain}>
              {appInfo.address && (
                <div>
                  <strong>Please send your product to:</strong>
                  <br />
                  <div>{appInfo.name}</div>
                  <div>{formatAddress(appInfo.address)}</div>
                </div>
              )}

              {caseFacts?.item_shipping_label && executeAction && (
                <Button
                  kind="primary"
                  className="mt-6 w-full"
                  onClick={() => setShowMarkAsSentConfirmation(true)}
                  disabled={caseFacts?.consumer_marked_item_sent ? true : false}
                >
                  {caseFacts?.consumer_marked_item_sent
                    ? 'Product sent'
                    : 'Mark as sent'}
                </Button>
              )}
              {
                // log postage action should also mark item as sent, right? -dc
              }
              {!caseFacts?.postage_receipts && (
                <Button
                  kind="primary"
                  className="mt-6 w-full"
                  onClick={logPostageAction}
                >
                  Log postage
                </Button>
              )}
              {showMarkAsSentConfirmation && (
                <Modal
                  kind="pop-up"
                  settings={{
                    text: 'You are about to mark item as sent. Are you sure you want to continue?',
                    primaryButtonText: 'Yes',
                    outlineButtonText: 'No, cancel',
                    handleClose: () => setShowMarkAsSentConfirmation(false),
                    handlePrimaryClick: async () => {
                      setShowMarkAsSentConfirmation(false);
                      executeAction &&
                        (await executeAction('mark_item_sent', {
                          consumer_marked_item_sent: true,
                        }));
                    },
                    handleOutlineClick: () =>
                      setShowMarkAsSentConfirmation(false),
                  }}
                />
              )}
            </div>
          </>
        ) : (
          <div className={styles.boxGray}>
            {data?.kv?.internal_comment &&
              renderRowWithLabel(
                'Internal comment',
                data?.kv?.internal_comment
              )}
            {data?.kv?.comment_to_customer &&
              renderRowWithLabel(
                'Comment to customer',
                data?.kv?.comment_to_customer
              )}
          </div>
        )}
      </div>
    );
  };

  const renderAssginedThirdParty = (
    data: any // todo: fix type
  ) => {
    return (
      <div>
        {!customer ? (
          <div className={styles.boxGray}>
            <div>
              {getName()} assigned the claim to{' '}
              <strong>{data.kv.repairer_business_name}</strong> (3rd Party
              Repair).
            </div>
            <div>
              <strong>Repairer contact details</strong>
              <br />
              {data.kv.repairer_name && (
                <>
                  <strong>Repairer name:</strong> {data.kv.repairer_name}
                  <br />
                </>
              )}
              <strong>Contact number:</strong> +
              {data.kv.repairer_contact_details?.contact_number}
              <br />
              <strong>Contact email:</strong>{' '}
              {data.kv.repairer_contact_details?.repairer_email}
              <br />
              <strong>Address</strong>:<br />
              {data?.kv?.repairer_address
                ? formatAddress(data?.kv?.repairer_address)
                : '-'}
            </div>
            {data?.kv?.internal_comment &&
              renderRowWithLabel(
                'Internal comment',
                data?.kv?.internal_comment
              )}
            {data?.kv?.comment_to_customer &&
              renderRowWithLabel(
                'Comment to customer',
                data?.kv?.comment_to_customer
              )}
            {getName() && renderRowWithLabel('User', getName())}
          </div>
        ) : (
          <div className={styles.boxGray}>
            <div>
              {getName()} has assigned your claim to{' '}
              <strong>{data.kv.repairer_business_name}</strong> for further
              action. <strong>{data.kv.repairer_business_name}</strong> has been
              informed of your claim and will be in touch soon. The contact
              information of <strong>{data.kv.repairer_business_name}</strong>{' '}
              can be found below.
            </div>
            {data?.kv?.comment_to_customer &&
              renderRowWithLabel('Comment', data?.kv?.comment_to_customer)}
            <div>
              <strong>Contact details</strong>
              <br />
              <strong>Business name:</strong> {data.kv.repairer_business_name}
              <br />
              <strong>Contact number:</strong> +
              {data.kv.repairer_contact_details?.contact_number}
              <br />
              <strong>Contact email:</strong>{' '}
              {data.kv.repairer_contact_details?.repairer_email}
              <br />
              <strong>Address</strong>:<br />
              {data?.kv?.repairer_address
                ? formatAddress(data?.kv?.repairer_address)
                : '-'}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderLogRepair = (
    data: any // todo: fix type
  ) => {
    return (
      <div>
        <div className="flex">
          <div className="w-full">
            <div className="w-full text-base text-gray-500">Work log</div>
          </div>
        </div>
        <div className={styles.boxGray}>
          <div>
            {/* TODO show parts_used_for_repair? */}
            {getName()} logged work done by{' '}
            {data?.kv?.delegate_repairer_name ?? repairerName}:{' '}
            {data.kv.total_repairing_time}
          </div>
          {data?.kv?.internal_comment &&
            renderRowWithLabel('Internal comment', data?.kv?.internal_comment)}
        </div>
      </div>
    );
  };

  const renderRepairCompleted = (
    data: any // todo: fix type
  ) => {
    return (
      <div>
        <div className={styles.boxGray}>
          <div>
            {getName()} has <strong>completed your repair</strong>. You will
            receive a logistics update soon.
          </div>
          {data?.kv?.comment_to_customer &&
            renderRowWithLabel('Comment', data?.kv?.comment_to_customer)}
        </div>
      </div>
    );
  };

  const renderLogPostageCustomer = (
    data: any // todo: fix type
  ) => {
    return (
      <div>
        <div className={styles.boxGray}>
          {data?.kv?.outbound_carrier === '-' &&
          data?.kv?.outbound_tracking_number === '-' ? (
            <div>
              {getName()} postage has been logged without details provided
            </div>
          ) : (
            <>
              <div>
                {getName()} has posted your item back to you with{' '}
                <strong>{data.kv.outbound_carrier}</strong>.
              </div>
              {data?.kv?.outbound_address &&
                renderRowWithLabel(
                  'Your item will be sent to',
                  formatAddress(data?.kv?.outbound_address) ?? '-'
                )}
              {data?.kv?.outbound_tracking_number &&
                renderRowWithLabel(
                  'Tracking number',
                  data?.kv?.outbound_tracking_number
                )}
              {data?.kv?.comment_to_customer &&
                renderRowWithLabel(
                  'Comment to customer',
                  data?.kv?.comment_to_customer
                )}
            </>
          )}
        </div>
      </div>
    );
  };

  const renderLogPostageEnterprise = (
    data: any // todo: fix type
  ) => {
    return (
      <div>
        <div className={styles.boxGray}>
          <div>
            {getName()} logged postage with{' '}
            <strong>{data.kv.outbound_carrier}</strong>.
          </div>
          {data?.kv?.outbound_address &&
            renderRowWithLabel(
              'Postage address',
              formatAddress(data?.kv?.outbound_address) ?? '-'
            )}
          {data?.kv?.outbound_tracking_number &&
            renderRowWithLabel(
              'Tracking number',
              data?.kv?.outbound_tracking_number
            )}
          {data?.kv?.comment_to_customer &&
            renderRowWithLabel(
              'Comment to customer',
              data?.kv?.comment_to_customer
            )}
        </div>
      </div>
    );
  };

  const renderCloseClaim = (
    data: any // todo: fix type
  ) => {
    return (
      <div>
        {customer ? (
          <div className={styles.boxGray}>
            <div>{getName()} closed this claim.</div>
            {data?.kv?.comment_to_customer &&
              renderRowWithLabel('Comment', data?.kv?.comment_to_customer)}
          </div>
        ) : (
          <div className={styles.boxGray}>
            <div>{getName()} closed this claim.</div>
            {data?.kv?.comment_to_customer &&
              renderRowWithLabel(
                'Comment to customer',
                data?.kv?.comment_to_customer
              )}
            {data?.kv?.internal_comment &&
              renderRowWithLabel(
                'Internal comment',
                data?.kv?.internal_comment
              )}
          </div>
        )}
      </div>
    );
  };

  const renderStatus = (status: ExternalRepairInvoiceStatus) => (
    <div
      className={`inline-flex rounded-md py-[2px] px-3 ${
        status === ExternalRepairInvoiceStatus.REJECTED
          ? 'bg-red-100 text-red-800'
          : ''
      } ${
        status === ExternalRepairInvoiceStatus.APPROVED
          ? 'bg-green-100 text-green-800'
          : ''
      } ${
        status === ExternalRepairInvoiceStatus.IN_REVIEW ||
        status === ExternalRepairInvoiceStatus.UNSUBMITTED
          ? 'bg-orange-100 text-orange-800'
          : ''
      } `}
    >
      {status ? toTitleCase(status) : '-'}
    </div>
  );

  const renderRepairInvoice = (data: CFCF_ExternalRepairInvoice[]) => {
    return (
      <div>
        <div className="flex items-center gap-2">
          <div className="text-base text-gray-500">New invoice update </div>
          {renderStatus(data[0].status)}
        </div>
        <div className={styles.boxGray}>
          {renderRowWithLabel(
            'Total cost',
            getCurrencyFormat(
              data[0].totalCost.amount,
              data[0].totalCost.currency
            )
          )}
        </div>
        <div key={data[0].file.url} className="mb-3 flex-grow">
          <UploadedFileCard data={data[0].file} status={'preview'} />
        </div>
      </div>
    );
  };

  function categorizeFileEventUpdates(updates: CFCF_FileUpdateEvent[]) {
    return updates.reduce((acc: any, update) => {
      const { category } = update;
      if (!acc[category]) acc[category] = [];
      acc[category].push(update);
      return acc;
    }, {});
  }

  /* ------------------------------ File updates ------------------------------ */
  let fileUpdateMessages: any[] = []; // todo
  if (data.kv?.file_update_event) {
    const categorizedUpdates = categorizeFileEventUpdates(
      data.kv.file_update_event
    );
    fileUpdateMessages = Object.keys(categorizedUpdates).map((category) => {
      const updates = categorizedUpdates[category];
      let label;
      switch (category) {
        case CFCF_FileUpdateEventCategory.PROOF_OF_PURCHASE:
          label = 'proof of purchase';
          break;
        case CFCF_FileUpdateEventCategory.CLAIM_EVIDENCE:
          label = 'supporting materials';
          break;
        case CFCF_FileUpdateEventCategory.SHIPPING_LABEL:
          label = 'shipping label';
          break;
        default:
          label = 'files';
          break;
      }

      const files = updates.map((update: CFCF_FileUpdateEvent) => update.file);
      return { data, action: updates[0].action, label, files };
    });
  }

  const renderMessage = () => {
    //TODO check other type messages

    // todo handle this one same way as above
    if (data.kv?.postage_receipts?.length && !customer)
      return renderUploadUpdate(
        data,
        CFCF_FileUpdateEventAction.UPLOAD, //todo temp
        'postage receipts',
        data.kv?.postage_receipts
      );
    if (data.kv?.internal_case_files?.length && !customer) {
      return renderInternalCaseFilesUpdate(data, 'an internal file.');
    }
    if (
      data.kv?.delegate_repairer_name &&
      data.kv?.delegate_repairer_name !== 'Unassigned' &&
      !data.kv?.claim_outcome &&
      !customer
    )
      return renderDelegate(data);
    if (data.newState === 'preliminary_assessment')
      return renderPreliminary(data);
    if (data.newState === 'inbound_post' && customer)
      return renderRequestItemFromCustomer(data);
    if (data.kv?.preliminary_assessment)
      return renderPreliminaryCompleted(data);
    if (data.kv?.final_assessment) return renderFinalAssessementCompleted(data);
    if (data.kv?.repairer_business_name) return renderAssginedThirdParty(data);
    if (data.oldState === 'third_party_repair' && data.newState === 'closed')
      return renderLogRepairAsCompleted(data);
    if (data.newState === 'closed') return renderCloseClaim(data);
    if (data.kv?.cost_to_repair) return renderQuoteCustomerForClaim(data);
    if (data.kv?.cost_to_repair_accepted !== undefined)
      return renderQuoteCostAcceptance(data);
    if (data.template) return renderEmailSent(data);
    if (
      data.kv?.total_repairing_time &&
      data.kv?.repair_work_registry &&
      !customer
    )
      return renderLogRepair(data);
    if (data.newState === 'pending_outbound' && customer)
      return renderRepairCompleted(data);
    if (data.kv?.outbound_carrier && customer)
      return renderLogPostageCustomer(data);
    if (data.kv?.outbound_carrier && !customer)
      return renderLogPostageEnterprise(data);
    if (
      data.newState &&
      data.oldState &&
      !customer &&
      getStationByKey(data.newState).label !== '-' &&
      data.oldState !== data.newState
    )
      return renderNewState(data);
    if (
      (data.kv?.internal_comment || data.kv?.comment_to_customer) &&
      !customer
    )
      return renderInternalComment(data);
    if (data.kv?.comment_to_customer) return renderComment(data);
    if (
      data.kv?.external_repair_invoices &&
      data.kv?.external_repair_invoices.length > 0
    ) {
      return renderRepairInvoice(data.kv?.external_repair_invoices);
    }
    //else if (data.kv?.preliminary_assessment) return renderAssessment(data);
    else return null;
  };

  const renderState = () => {
    return (
      <div className="flex">
        <div className="my-auto mr-2 text-base text-gray-500">
          Status updated to{' '}
        </div>
        <div className="bg-primary-100 flex h-[30px] shrink-0 gap-1 rounded-md px-2 py-0.5 text-center font-medium">
          <Cog8ToothIcon className="text-primary-800 my-auto h-[11px] w-[11px]" />
          <span className="text-primary-800 my-auto text-sm">
            {data.newState && getStationByKey(data.newState).label}
          </span>
        </div>
      </div>
    );
  };

  if (isLoading) return <Skeleton height={80} />;

  if (fileUpdateMessages.length > 0)
    return fileUpdateMessages.map((msg) =>
      renderUploadUpdate(msg.data, msg.action, msg.label, msg.files)
    );

  return renderMessage() || data.newState ? (
    <div className="font-nunito my-4 flex flex-col rounded-md border border-gray-200 py-3.5 px-4 shadow-sm">
      {data.newState && renderState()}

      {renderMessage()}
      <div className="text-right text-base text-gray-500">
        {formatUnixTime(data.t, 'h:mmaaa')}
      </div>
    </div>
  ) : (
    <></>
  );
}

export default MessageWithLabelAndTag;
