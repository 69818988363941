import { FileStorageContext } from '@rabbit/bizproc/react';
import {
  Button,
  Input,
  Modal,
  UploadedFileCardv2,
} from '@rabbit/elements/shared-components';
import {
  CaseflowContext,
  CaseflowInterface,
} from '@rabbit/sage/context/CaseflowContext';
import { useAppInfo } from '@rabbit/sage/utils/helpers';
import { Form, Formik, FormikProps } from 'formik';
import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { SageFileUploader } from '../../organisms/upload-wrapper/SageFileUploader';
import {
  DocTypeShapeTypes,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import { OurDateTime } from '@rabbit/utils/ts';
import { toast } from 'react-toastify';
import {
  CaseFlowActions_Fatbikes_ExternalRepairer,
  CFCF_ExternalRepairInvoice,
  ExternalRepairInvoiceStatus,
} from '@rabbit/bizproc/core';
import { PersonaTypeSingleLetter } from '@rabbit/data/types';
import { ModalAddEditInvoiceModes } from '../../organisms/ModalAddEditInvoice/ModalAddEditInvoice';

interface FormValuesShape {
  //invoice: UserUploadedDocument;
  cost: {
    amount: string;
    currency: string;
  };
  rejection_reason?: string;
  external_repairer_comment?: string;
}

interface AddEditInvoiceFormRepairerProps {
  handleClose: () => void;
  data?: CFCF_ExternalRepairInvoice;
  mode: ModalAddEditInvoiceModes;
}
export default function AddEditInvoiceFormRepairer({
  handleClose,
  data,
  mode = 'add-edit',
}: AddEditInvoiceFormRepairerProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showInternalComment, setShowInternalComment] = useState(false);
  const {
    caseActors,
    caseId,
    alterCaseFacts,
    caseFacts,
    operatingPersona,
    operatingPersonaSingleKey,
    executeAction,
    moveSpotlight,
    caseAlterability,
  } = useContext(CaseflowContext) || ({} as CaseflowInterface);
  const fileStorageContext = useContext(FileStorageContext);
  const { uploadQueueState, moveCompletedUploadsToAttached } =
    fileStorageContext || {};

  const formikRef = useRef<FormikProps<FormValuesShape> | null>(null);

  const appInfo = useAppInfo();
  const { t } = useTranslation();

  /* ---------------------- Invoice creation and editing ---------------------- */

  async function handleSubmit(values: FormValuesShape) {
    setIsSubmitting(true);

    if (mode === 'submit') {
      try {
        await submitInvoice();
      } catch (e) {
        console.error(e);
        toast.error('Failed to submit invoice');
      } finally {
        setIsSubmitting(false);
      }
      return;
    }

    const { cost, external_repairer_comment } = values;

    let factsToAlter = {};

    /* ------------------------------ New invoices ------------------------------ */
    if (!data) {
      if (!uploadQueueState?.completed?.[0].uploadedFile) {
        toast.error('No file uploaded, failed to add invoice');
        setIsSubmitting(false);
        return;
      }

      if (!cost.amount || !cost.currency) {
        toast.error('Please enter cost of repair');
        setIsSubmitting(false);
        return;
      }

      let invoiceArray = caseFacts?.external_repair_invoices ?? [];
      const newInvoice: CFCF_ExternalRepairInvoice = {
        file: uploadQueueState?.completed[0].uploadedFile,
        totalCost: {
          amount: Number(cost.amount),
          currency: cost.currency,
        },
        status: ExternalRepairInvoiceStatus.UNSUBMITTED,
        timestamp: OurDateTime.nowUTCTimestamp(),
      };

      factsToAlter = {
        external_repair_invoices: [...invoiceArray, newInvoice],
        ...(external_repairer_comment && {
          external_repairer_comment: {
            comment: external_repairer_comment,
            author: operatingPersona,
          },
        }),
      };
    }
    /* ------------------------ Editing existing invoices ----------------------- */
    if (data) {
      factsToAlter = {
        external_repair_invoices: caseFacts?.external_repair_invoices?.map(
          (invoice) => {
            if (invoice.timestamp === data.timestamp) {
              return {
                ...invoice,
                totalCost: {
                  amount: Number(cost.amount),
                  currency: cost.currency,
                },
              };
            }
            return invoice;
          }
        ),
        ...(external_repairer_comment && {
          external_repairer_comment: {
            comment: external_repairer_comment,
          },
        }),
      };
    }

    try {
      if (Object.keys(factsToAlter).length > 0)
        await alterCaseFacts(factsToAlter);

      if (!data && uploadQueueState?.completed) {
        moveCompletedUploadsToAttached?.(uploadQueueState?.completed);
        toast.success('Invoice added successfully!');
      }

      if (data) {
        toast.success('Invoice edited successfully!');
      }

      handleClose();
    } catch (err) {
      console.error(err);
      toast.error('Failed to add invoice');
    } finally {
      setIsSubmitting(false);
    }
  }

  /* --------------------------- Invoice submission --------------------------- */

  async function submitInvoice() {
    if (executeAction && moveSpotlight && data && caseAlterability) {
      try {
        // Alter the case facts to update the invoice status
        const invoiceArray = caseFacts?.external_repair_invoices ?? [];
        const alteredInvoices = invoiceArray.map((invoice) => {
          if (invoice.timestamp === data.timestamp) {
            return {
              ...invoice,
              status: ExternalRepairInvoiceStatus.IN_REVIEW,
            };
          }
          return invoice;
        });

        alterCaseFacts &&
          alterCaseFacts({
            external_repair_invoices: alteredInvoices,
          });

        await executeAction(
          CaseFlowActions_Fatbikes_ExternalRepairer.SubmitInvoice
        );
        await moveSpotlight(PersonaTypeSingleLetter.Warrantor);
        handleClose();
        toast.success(t('Invoice submitted successfully!'));
      } catch (e) {
        console.log(e);
        toast.error(t('Failed to submit invoice'));
      }
    }
  }

  const initialValues: FormValuesShape = {
    cost: {
      amount: data?.totalCost.amount.toString() ?? '',
      currency: data?.totalCost.currency ?? appInfo.currency,
    },
    external_repairer_comment: '',
    rejection_reason: data?.rejectionReason ?? '',
  };

  const repairerValidationSchema = Yup.object().shape({
    // we get this directly from the uploaded queue state rather than adding it to the form
    // In the future I want to sync the stuff from SageFileUploader with the form somehow - DC
    // invoice: Yup.array(Yup.mixed()),
    cost: Yup.object({
      amount: Yup.string().required(t('Please enter amount')).trim(),
      currency: Yup.string().min(1).required(),
    }),
    external_repairer_comment: Yup.string().trim(),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={repairerValidationSchema}
      formikRef={formikRef}
    >
      {(props) => {
        return (
          <Form className="flex flex-col gap-4 p-4">
            {/* We only show the uploader if this is a new invoice. Otherwise they're not allowed to edit the file, just the cost */}
            {!data && (
              <SageFileUploader
                label={t('Invoice document')}
                identifiers={{
                  category: UploadedFileCategories.ExternalRepairInvoices,
                  docType: {
                    docid: caseId ?? '',
                    type: DocTypeShapeTypes.Case,
                  },
                  personaId: caseActors?.external_repairer ?? '',
                }}
                alterCaseFacts={alterCaseFacts}
                accepts={['image/*', '.pdf']}
                shouldAutoUpdateDocs={false}
                // disable uploading more than one file
                disabled={
                  uploadQueueState?.completed &&
                  uploadQueueState?.completed?.length >= 1
                }
              />
            )}
            {data && (
              <UploadedFileCardv2
                kind="existing"
                data={data.file}
                docid=""
                uploadCategory={UploadedFileCategories.ExternalRepairInvoices}
              />
            )}
            <Input
              type="currency"
              name="cost"
              label={t('Cost of repair*')}
              settings={{
                id: 'cost',
                placeholder: t('Cost of repair'),
                currency: data?.totalCost.currency ?? appInfo.currency,
                hint: '*required',
                disabled:
                  mode === 'repairer-review' || mode === 'submit'
                    ? true
                    : false,
              }}
            />
            {data?.rejectionReason && (
              <Input
                type="textarea"
                label={t('Rejection reason')}
                name="rejection_reason"
                settings={{
                  id: 'rejection_reason',
                  allowSpecialCharacter: true,
                  disabled: true,
                }}
              />
            )}
            {mode !== 'repairer-review' && !showInternalComment && (
              <div className="mt-4">
                <Button
                  kind="outline"
                  type="button"
                  className="w-full"
                  onClick={() => setShowInternalComment(true)}
                >
                  {t('Add internal comment')}
                </Button>
              </div>
            )}
            {showInternalComment && (
              <div className="mt-3">
                <Input
                  type="rich-text"
                  label={t('Internal comment')}
                  name={'external_repairer_comment'}
                  settings={{
                    id: 'external_repairer_comment',
                    allowSpecialCharacter: true,
                  }}
                />
              </div>
            )}
            <div className="mt-4 grid grid-cols-2 gap-3">
              {mode !== 'repairer-review' && (
                <Button
                  kind="primary"
                  type="submit"
                  disabled={
                    isSubmitting ||
                    Object.keys(props.errors).length > 0 ||
                    (data ? false : uploadQueueState?.completed.length !== 1)
                  }
                >
                  {mode === 'submit'
                    ? t('Submit invoice')
                    : !data
                    ? t('Add invoice')
                    : t('Edit invoice')}
                </Button>
              )}
              <Button
                kind="outline_red"
                type="submit"
                onClick={handleClose}
                disabled={isSubmitting}
              >
                {mode === 'repairer-review' ? t('Close') : t('Cancel')}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
