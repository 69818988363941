/* A list of types and interfaces for caseflow facts, stations, etc.
 *
 * Not currently used for the most part, but listing them here both provides documentation for the
 * actual shape of the data used in facts and allows them to be used right away once support for them is added.
 *
 * The CFCF prefix stands for CaseFlow Case Fact.
 * So things don't end up all over the place when it comes to casing and naming conventions again, I'm now deciding that all CFCF types
 * will have their object keys using snake_case, while the interfaces themselves are in CamelCase save for the prefix.
 *
 * A WIP. Some of these might end up being common to all caseflow configurations, and should be moved to a shared location.
 */

import { Money, UserUploadedDocument } from '@rabbit/data/types';

/* -------------------------------------------------------------------------- */
/*                                  Stations                                  */
/* -------------------------------------------------------------------------- */

export enum CaseFlowStations_Fatbikes {
  BIRTH = '_birth',
  PRELIMINARY_ASSESSMENT = 'preliminary_assessment',
  INITIALLY_ASSESSED = 'initially_assessed',
  ASSIGNED = 'assigned',
  REPAIR_INVOICE_ASSESSMENT = 'repair_invoice_assessment',
  INVOICE_ACCEPTED = 'invoice_accepted',
  IN_REPAIR = 'in_repair',
  REPAIRED = 'repaired',
  CLOSED = 'closed',
}
/* -------------------------------------------------------------------------- */
/*                                    Facts                                   */
/* -------------------------------------------------------------------------- */

export enum ExternalRepairInvoiceStatus {
  UNSUBMITTED = 'Unsubmitted',
  IN_REVIEW = 'In review',
  REJECTED = 'Rejected',
  APPROVED = 'Approved',
}
export interface CFCF_ExternalRepairInvoice {
  file: UserUploadedDocument;
  totalCost: Money;
  status: ExternalRepairInvoiceStatus;
  rejectionReason?: string;
  timestamp: number;
}

/* -------------------------------------------------------------------------- */
/*                                   Actions                                  */
/* -------------------------------------------------------------------------- */
export enum CaseFlowActions_Fatbikes_ExternalRepairer {
  /* ---------------------------- Station specific ---------------------------- */
  SubmitInvoice = 'submit_invoice',
  InitiateRepair = 'initiate_repair',
  MarkRepairComplete = 'mark_repair_complete',
  /* ----------------------------- Global actions ----------------------------- */
  ContactCustomer = 'contact_customer_generic',
  SpotlightOnConsumer = 'spotlight_on_consumer',
  SpotlightOnRepairer = 'spotlight_on_repairer',
  SpotlightOnWarrantor = 'spotlight_on_warrantor',
}

export enum CaseFlowActions_Fatbikes_Warrantor {
  /* ------------------------ Station specific actions ------------------------ */
  ApproveInvoice = 'approve_invoice',
  RejectInvoice = 'reject_invoice',
  CloseCase = 'close_case',
  /* ----------------------------- Global actions ----------------------------- */
  ContactCustomer = 'contact_customer_generic',
  InternalComment = 'internal_comment_generic',
  AssignCase = 'assign_case',
  // spotlights
  SpotlightOnConsumer = 'spotlight_on_consumer',
  SpotlightOnRepairer = 'spotlight_on_repairer',
  SpotlightOnWarrantor = 'spotlight_on_warrantor',
  // station movement
  MoveToPreliminaryAssessment = 'move_to_preliminary_assessment',
  MoveToInitiallyAssessed = 'move_to_initially_assessed',
  MoveToInRepair = 'move_to_in_repair',
  MoveToRepaired = 'move_to_repaired',
  MoveToClosed = 'move_to_closed',
  // cost logging
  LogAdministrativeCost = 'log_costs_administrative_time',
  LogPartsUsedCost = 'log_costs_parts_used',
  LogRepairTime = 'log_costs_repair_time',
  LogShippingCost = 'log_costs_shipping',
  LogTravelCost = 'log_costs_travel',
  LogOtherCost = 'log_costs_other',
}
