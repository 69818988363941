import { z } from 'zod';
import { MakeCloudFunctionSpec } from '@rabbit/firebase/doctype';
import {
  Z_AppliedWarrantyOption,
  Z_DTWarranty_Template,
  Z_SRVInfo,
  Z_Warranty,
  Z_WarrantyLink,
  Z_WarrantyTemplateLink,
  Z_WarrantyTemplateParameters,
  Z_ConsumerInfo,
  Z_ConsumerLink,
  Z_TenantLink,
} from '@rabbit/data/types';
import { ICF_Base } from '../base';

/** Get Available Warranty Templates */

const ICF_GetAvailableWarrantyTemplates = z
  .object({
    warrantor: z.string(),
    parameters: Z_WarrantyTemplateParameters,
  })
  .merge(ICF_Base);

const OCF_GetAvailableWarrantyTemplates = z.array(Z_DTWarranty_Template);

export const CFC_GetAvailableWarrantyTemplates = MakeCloudFunctionSpec(
  'cfcgetavailablewarrantytemplates',
  ICF_GetAvailableWarrantyTemplates,
  OCF_GetAvailableWarrantyTemplates
);

/* ---------------------------- Register Warranty --------------------------- */

const ICF_RegisterWarranty = z
  .object({
    warrantor: z.string(),
    templateLink: Z_WarrantyTemplateLink,
    warranty: z.object({
      startDate: z.coerce.date().nullable(), // today if null
      options: z.array(Z_AppliedWarrantyOption),
      salesRep: z.string().or(z.null()).or(z.undefined()),
    }),
    holding: Z_SRVInfo,
    consumer: Z_ConsumerInfo,
    deciderOutput: z.optional(z.any()), // TODO: add proper type, make mandatory in the future - DC
    //deciderOutput: z.optional(Z_DeciderOutput), // enable once decider has been implemented throughout and types are completed and tested
  })
  .merge(ICF_Base);

const OCF_RegisterWarranty = z.object({
  warrantyLink: z.string(),
  holdingLink: z.string(),
});

export const CFC_RegisterWarranty = MakeCloudFunctionSpec(
  'cfcregisterwarranty',
  ICF_RegisterWarranty,
  OCF_RegisterWarranty
);

/* ------------------- Register SRV Holding with Warranty ------------------- */
// it's basically the v2 of the above function, but with a different name

const ICF_RegisterSRVHoldingWithWarranty = z
  .object({
    warrantor: z.string(),
    templateLink: Z_WarrantyTemplateLink,
    warranty: z.object({
      startDate: z.coerce.date().nullable(), // today if null
      options: z.array(Z_AppliedWarrantyOption),
      salesRep: z.string().or(z.null()).or(z.undefined()),
    }),
    holding: Z_SRVInfo,
    consumer: Z_ConsumerInfo,
    deciderOutput: z.any(), // TODO: add proper type, DC
    //deciderOutput: z.optional(Z_DeciderOutput), // enable once decider has been implemented throughout and types are completed and tested
    offerLink: z.optional(z.string()),
    warrantyLink: z.string().or(z.null()).or(z.undefined()),
  })
  .merge(ICF_Base);

const OCF_RegisterSRVHoldingWithWarranty = z.object({
  warrantyLink: z.string(),
  holdingLink: z.string(),
});

export const CFC_RegisterSRVHoldingWithWarranty = MakeCloudFunctionSpec(
  'cfcregistersrvholdingwithwarranty',
  ICF_RegisterSRVHoldingWithWarranty,
  OCF_RegisterSRVHoldingWithWarranty
);

/* ------------------------------ Void Warranty ----------------------------- */
const ICF_VoidWarranty = z
  .object({
    warrantyLink: Z_WarrantyLink,
  })
  .merge(ICF_Base);

export const OCF_VoidWarranty = z.object({
  warranty: Z_Warranty.or(z.null()),
});

export const CFC_VoidWarranty = MakeCloudFunctionSpec(
  'cfcvoidwarranty',
  ICF_VoidWarranty,
  OCF_VoidWarranty
);

/* ----------------------- Commercial Use Status Warranty Update ---------------------- */

export const ICF_CommercialUseStatusWarrantyUpdate = z
  .object({
    consumerLink: Z_ConsumerLink,
    premiumTenantLink: Z_TenantLink,
  })
  .merge(ICF_Base);

export const OCF_CommercialUseStatusWarrantyUpdate = z.object({
  warranties: z.array(Z_WarrantyLink),
});

export const CFC_CommercialUseStatusWarrantyUpdate = MakeCloudFunctionSpec(
  'cfccommercialusestatuswarrantyupdate',
  ICF_CommercialUseStatusWarrantyUpdate,
  OCF_CommercialUseStatusWarrantyUpdate
);
