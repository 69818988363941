/* eslint-disable @nx/enforce-module-boundaries */
import { FileStorageContext } from '@rabbit/bizproc/react';
import {
  LoadingSpinner,
  UploadFilesFormv2,
} from '@rabbit/elements/shared-components';
import { FileUploaderWrapperProps } from '@rabbit/elements/shared-types';
import { useContext, useEffect, useState } from 'react';
import { SageFileUploadAutoUpdater } from './SageFileUploadAutoUpdater';

// TODO: Add in file array length limiter: e.g. 1 file max, upload is disabled if they are already in current files or try to upload more than one - dc
// TODO: Handle all caseflow related operations here and in the context - dc
export function SageFileUploader(props: FileUploaderWrapperProps) {
  const {
    label,
    labelClasses,
    identifiers,
    currentFiles,
    accepts,
    onUploadCompleted,
    alterCaseFacts,
    shouldAutoUpdateDocs,
    disabled,
  } = props;
  const [currentFilesState, setCurrentFilesState] = useState(currentFiles);
  const fileStorageContext = useContext(FileStorageContext);
  const {
    uploadFiles,
    uploadQueueState,
    isUpdating,
    deleteFile,
    shouldRefetch,
    setShouldRefetch,
    clearAttached,
  } = fileStorageContext || {};

  // If we don't have any current files from the parent, we might have to fetch them
  useEffect(() => {
    if ((!currentFiles || shouldRefetch) && identifiers.docType?.docid) {
      setCurrentFilesState(currentFiles ?? []);
      setShouldRefetch?.(false);
    }

    // clear attached files from state once currentFiles has been updated
    if (
      currentFiles &&
      uploadQueueState?.attached &&
      uploadQueueState?.attached.length > 0
    ) {
      setCurrentFilesState(currentFiles ?? []);
      if (uploadQueueState?.attached) {
        const filesToBeCleared = uploadQueueState.attached.flatMap((file) =>
          file.category === identifiers.category ? [file] : []
        );
        clearAttached?.(filesToBeCleared);
      }
    }
  }, [
    shouldRefetch,
    currentFiles,
    identifiers.category,
    identifiers.docType,
    uploadQueueState?.attached,
  ]);

  if (
    !uploadFiles ||
    !uploadQueueState ||
    !currentFilesState ||
    !deleteFile ||
    !setShouldRefetch ||
    isUpdating === undefined
  )
    return <LoadingSpinner size="sm" />;

  return (
    <>
      {shouldAutoUpdateDocs && (
        <SageFileUploadAutoUpdater fileCategory={identifiers.category} />
      )}
      <UploadFilesFormv2
        {...props}
        queue={uploadQueueState}
        currentFiles={currentFilesState}
        isUpdating={isUpdating}
        actions={{
          uploadFiles,
          deleteFile,
          alterCaseFacts,
          setShouldRefetch,
        }}
        onUploadCompleted={onUploadCompleted}
        disabled={disabled}
      />
    </>
  );
}

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

// const fetchShippingLabelFiles = async (holdingId: string) => {

// }
