import {
  LoadingSpinner,
  Modal,
  TabsGeneric,
} from '@rabbit/elements/shared-components';
import UpdateClaimDetailsForm, {
  ClaimDetailsForm,
} from '../forms/UpdateClaimDetailsForm/UpdateClaimDetailsForm';
import { ModalSettingsShape } from '@rabbit/elements/shared-components';
import { CaseflowContext } from '../../../context/CaseflowContext';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileStorageContext } from '@rabbit/bizproc/react';
import ClaimPoPAndEvidenceUpload from '../ClaimPoPAndEvidenceUpload/ClaimPoPAndEvidenceUpload';
import UpdateCustomerDetailsForm from '../forms/UpdateCustomerDetailsForm/UpdateCustomerDetailsForm';

export interface ModalEditClaimDetailsProps {
  modalSettings: ModalSettingsShape;
  claimDetails: any;
  handleClose: () => void;
}

export interface SharedValuesShape {
  claimDetails: ClaimDetailsForm;
}

function ModalEditClaimDetails({
  claimDetails,
  handleClose,
}: ModalEditClaimDetailsProps) {
  const { caseFlowCase } = useContext(CaseflowContext) || {};
  const [sharedValues, setSharedValues] = useState<SharedValuesShape>(
    {} as any
  );
  const { unusedCompletedCleanup } = useContext(FileStorageContext) || {};

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const claimsFlowLite = t('CFG_CLAIMS_FLOW') === 'lite';

  useEffect(() => {
    return () => {
      unusedCompletedCleanup?.();
    };
  }, []);

  if (!caseFlowCase) return <LoadingSpinner size="xs" />;

  const renderCustomerDetails = () => {
    return (
      <UpdateCustomerDetailsForm
        claimDetails={claimDetails}
        handleClose={handleClose}
        caseFlowCase={caseFlowCase}
        setLoading={setLoading}
      />
    );
  };

  const renderClaimDetails = () => {
    return (
      <UpdateClaimDetailsForm
        claimDetails={claimDetails}
        handleClose={handleClose}
        caseFlowCase={caseFlowCase}
        setLoading={setLoading}
        sharedValues={sharedValues}
        setSharedValues={setSharedValues}
      />
    );
  };

  const renderAttachements = () => {
    return <ClaimPoPAndEvidenceUpload handleClose={handleClose} />;
  };

  const tabs = claimsFlowLite
    ? [
        {
          label: t('Claim details'),
          children: renderClaimDetails(),
        },
        {
          label: t('Attachments'),
          children: renderAttachements(),
        },
      ]
    : [
        {
          label: t('Customer details'),
          children: renderCustomerDetails(),
        },
        {
          label: t('Claim details'),
          children: renderClaimDetails(),
        },
        {
          label: t('Attachments'),
          children: renderAttachements(),
        },
      ];

  return (
    <Modal
      kind="generic"
      settings={{
        title: t('Update claim details'),
        handleClose: handleClose,
      }}
      isLoading={loading}
      className="m-auto w-[724px] rounded-md border bg-white"
    >
      <div className="p-5">
        <TabsGeneric items={tabs} query={false}></TabsGeneric>
      </div>
    </Modal>
  );
}

export default ModalEditClaimDetails;
