import { Form, Formik } from 'formik';
import {
  Button,
  Input,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { CaseflowContext } from 'apps/sage/src/context/CaseflowContext';
import { MANDRILL_TEMPLATES } from '@rabbit/bizproc/react';
import { getConsumerURL, useAppInfo } from 'apps/sage/src/utils/helpers';
import { updateHoldingOnClose } from 'apps/sage/src/utils/firestore-helpers';
import { toast } from 'react-toastify';
import { PersonaTypeSingleLetter } from '@rabbit/data/types';
import { useTranslation } from 'react-i18next';

export interface LogRepairCompleteModalProps {}

interface FormValuesShape {
  cost_to_repair: { amount: number; currency: string };
  internal_comment: string;
  comment_to_customer: string;
}

const validationSchema = Yup.object().shape({
  cost_to_repair: Yup.object({
    amount: Yup.number().required('Please enter a valid amount'),
    currency: Yup.string().trim().required('Currency is required'),
  }).required('Please enter a repair cost'),
  internal_comment: Yup.string().trim(),
  comment_to_customer: Yup.string().trim(),
});

export function LogRepairCompleteModal({}: LogRepairCompleteModalProps) {
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showInternalComment, setShowInternalComment] = useState(false);

  const initialValues = {
    cost_to_repair: {
      amount: 0,
      currency: appInfo.currency ?? '',
    },
    internal_comment: '',
    comment_to_customer: '',
  };

  const {
    caseFacts,
    alterCaseFacts,
    alterCasePublicEmail,
    executeAction,
    moveSpotlight,
    setShowModal,
    operatingPersona,
    caseId,
  } = useContext(CaseflowContext) || {};

  if (
    !setShowModal ||
    !executeAction ||
    !alterCaseFacts ||
    !alterCasePublicEmail ||
    !moveSpotlight ||
    !operatingPersona ||
    !caseFacts ||
    !caseId
  )
    return <LoadingSpinner size={'xs'} />;

  const onSubmit = async (values: FormValuesShape) => {
    const { cost_to_repair, internal_comment, comment_to_customer } = values;

    setIsSubmitting(true);

    await moveSpotlight(PersonaTypeSingleLetter.Consumer);
    executeAction('log_authorised_repairer_repair_complete');

    const factsToAlter = {
      ...(internal_comment && {
        internal_comment: {
          comment: internal_comment,
          author: operatingPersona,
        },
      }),
      cost_to_repair: cost_to_repair,
      comment_to_customer: comment_to_customer,
    };

    const to = caseFacts?.consumer_email ?? '',
      from = appInfo.email_sender,
      template = MANDRILL_TEMPLATES.BODY_AUTHORISED_REPAIR_COMPLETE,
      business_name = appInfo.name ?? '',
      claim_id = caseId,
      first_name = caseFacts?.consumer_name ?? '',
      product_name = caseFacts.consumer_holding_name ?? '',
      subject =
        t('Your ') + business_name + t(' claim has been completed - ') + product_name,
      link_to_claim = `${getConsumerURL()}/repairs/${caseId}`;

    if (Object.keys(factsToAlter).length > 0) {
      await alterCaseFacts(factsToAlter);
      await alterCasePublicEmail({
        context: 'authorised_repair_complete',
        from,
        to,
        subject,
        template,
        substitutions: {
          subject,
          business_name,
          claim_id,
          first_name,
          comment_to_customer,
          product_name,
          link_to_claim,
        },
        shallBeSentViaBackend: true
      });
    }

    await updateHoldingOnClose(caseId, caseFacts.consumer_holding ?? '');
    setShowModal(false);
    toast.success('Claim updated successfully.');
  };

  return (
    <Modal
      settings={{
        title: 'Log repair as complete',
        headerBackground: true,
        handleClose: () => setShowModal(false),
      }}
      kind="generic"
      isLoading={isSubmitting}
      className="max-h-[768px] w-full max-w-[1024px] overflow-y-auto"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, values }) => (
          <Form className="mt-5 flex flex-col gap-3 px-4">
            <Input
              type="currency"
              label="Cost of repair"
              name="cost_to_repair"
              settings={{
                id: 'cost_to_repair',
                placeholder: '',
                currency: values?.cost_to_repair?.currency ?? appInfo.currency,
                hint: '*required',
              }}
            />
            <div className="mt-4">
              <Input
                type="rich-text"
                name="comment_to_customer"
                label="Comment to customer"
                settings={{
                  id: 'comment_to_customer',
                  placeholder: 'Comment to customer',
                }}
              />
            </div>
            {!showInternalComment && (
              <div className="mt-4">
                <Button
                  kind="outline"
                  type="button"
                  className="w-full"
                  onClick={() => setShowInternalComment(true)}
                >
                  Add internal comment
                </Button>
              </div>
            )}
            {showInternalComment && (
              <div>
                <Input
                  type="rich-text"
                  label="Internal comment"
                  name="internal_comment"
                  settings={{
                    id: 'internal_comment',
                    placeholder: '',
                    allowSpecialCharacter: true,
                  }}
                />
              </div>
            )}
            <div className="mt-8 flex gap-8">
              <Button kind="primary" type="submit" loading={isSubmitting}>
                Log as complete and close
              </Button>
              <Button kind="outline_red" onClick={() => setShowModal(false)}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default LogRepairCompleteModal;
