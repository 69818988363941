import { useContext, useState } from 'react';
import {
  Form,
  Formik,
  FormikProps,
  replace,
  useFormik,
  useFormikContext,
} from 'formik';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import {
  Button,
  ButtonIcon,
  Input,
  LoadingSpinner,
  Modal,
  Toggle,
} from '@rabbit/elements/shared-components';
import { toast } from 'react-toastify';
import { CaseflowContext } from 'apps/sage/src/context/CaseflowContext';
import { MANDRILL_TEMPLATES } from '@rabbit/bizproc/react';
import { getConsumerURL, useAppInfo } from 'apps/sage/src/utils/helpers';
import PartsElement from '../../../molecules/PartsElement/PartsElement';
import { PartValuesShape } from 'apps/sage/types';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { GazetteEmailParams } from '@rabbit/data/types';
import { useTranslation } from 'react-i18next';

export interface ReplacePartsOrProductModalProps {
  repair_work_registry: RepairWorkRegistryProps[];
}

interface FormValuesShape {
  comment_to_customer: string;
  parts_used_for_repair?: PartValuesShape[]; // Array to store parts
  internal_comment: string;
  claim_outcome: string;
}

interface RepairWorkRegistryProps {
  parts_used_for_repair: PartValuesShape[];
  timeStamp: number;
}

const initialValues = {
  comment_to_customer: '',
  parts_used_for_repair: undefined as any,
  internal_comment: '',
  claim_outcome: 'Repair',
};

const partsError =
  "Please select if you're going to replace whole product or individual parts";
const validationSchema = Yup.object().shape({
  comment_to_customer: Yup.string()
    .trim()
    .required("Comments to customer can't be empty")
    .test(
      'remove-html-tags',
      "Comments to customer can't be empty",
      (value) => {
        const div = document.createElement('div');
        div.innerHTML = value || '';
        return !value || div.textContent?.trim() !== '';
      }
    ),
  parts_used_for_repair: Yup.array()
    .min(1, partsError)
    .required(partsError)
    .when('whole_part', {
      is: false,
      then: Yup.array().required(partsError).min(1, partsError),
      otherwise: Yup.array(),
    }),
  internal_comment: Yup.string().trim(),
  claim_outcome: Yup.string().trim(),
  whole_part: Yup.boolean(),
});

export function ReplacePartsOrProductModal({
  repair_work_registry,
}: ReplacePartsOrProductModalProps) {
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ids, setIds] = useState<string[]>([]);
  const [replaceWholeProduct, setReplaceWholeProduc] = useState(false);
  const [, setClaimOutcome] = useState('');
  const [partsValues, setPartsValues] = useState<PartValuesShape[]>([]);
  const [showInternalComment, setShowInternalComment] = useState(false);

  const {
    caseFacts,
    alterCaseFacts,
    alterCasePublicEmail,
    executeAction,
    moveSpotlight,
    setShowModal,
    operatingPersona,
    operatingPersonaSingleKey,
    caseId,
  } = useContext(CaseflowContext) || {};

  if (
    !setShowModal ||
    !executeAction ||
    !alterCaseFacts ||
    !alterCasePublicEmail ||
    !moveSpotlight ||
    !operatingPersonaSingleKey ||
    !operatingPersona ||
    !caseFacts ||
    !caseId
  )
    return <LoadingSpinner size={'xs'} />;

  const handleAddElement = () => {
    const newId = uuidv4();
    setIds([...ids, newId]);
  };

  const handleDeleteElement = (removeId: string) => {
    setIds((ids) => ids.filter((id) => id !== removeId));
  };

  const handleToggleOnChange = async (props: FormikProps<FormValuesShape>) => {
    setReplaceWholeProduc(!replaceWholeProduct);
    const newClaimOutcome = replaceWholeProduct ? 'Repair' : 'Replace';
    setClaimOutcome(newClaimOutcome);
    await props.setFieldValue('whole_part', !replaceWholeProduct);
    const on = !replaceWholeProduct;
    if (on) {
      await props.setFieldValue('parts_used_for_repair', [undefined]);
    } else {
      await props.setFieldValue('parts_used_for_repair', []);
    }
    await props.setFieldValue('claim_outcome', newClaimOutcome);
  };

  const onSubmit = async (values: FormValuesShape) => {
    const { comment_to_customer, internal_comment, claim_outcome } = values;
    setIsSubmitting(true);

    const newArray = JSON.parse(JSON.stringify(repair_work_registry));
    newArray.push({
      parts_used_for_repair: partsValues,
      timeStamp: Date.now(),
    });

    await moveSpotlight(operatingPersonaSingleKey);
    executeAction('replace_part_or_product');

    try {
      const factsToAlter = {
        ...(internal_comment && {
          internal_comment: {
            comment: internal_comment,
            author: operatingPersona,
          },
        }),
        comment_to_customer: comment_to_customer,
        claim_outcome: claim_outcome,
        repair_work_registry: JSON.parse(JSON.stringify(newArray)),
      };

      if (Object.keys(factsToAlter).length > 0) {
        const context = 'replacement',
          from = appInfo.email_sender,
          to = caseFacts?.consumer_email ?? '',
          business_name = appInfo.name,
          template = MANDRILL_TEMPLATES.BODY_REPLACEMENT,
          claim_id = caseId,
          comment_to_customer = values.comment_to_customer,
          first_name = caseFacts?.consumer_name ?? '',
          preliminary_assessment = caseFacts?.preliminary_assessment ?? '',
          replacement_data = 'REPLACEMENT_DATA', //TODO: change this to the correct template
          product_name = caseFacts.consumer_holding_name ?? '',
          subject = t('Your item or part will be replaced by') + business_name + ' - '+ product_name,
          link_to_claim = `${getConsumerURL()}/repairs/${caseId}`;

        await alterCaseFacts(factsToAlter);
        await alterCasePublicEmail({
          context,
          from,
          to,
          subject,
          template,
          substitutions: {
            subject,
            business_name,
            claim_id: caseId,
            first_name,
            comment_to_customer,
            preliminary_assessment,
            replacement_data,
            product_name,
            link_to_claim,
          },
          shallBeSentViaBackend: true
        });
      }

      setShowModal(false);
      toast.success('Claim updated successfully.');
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong, please try again');
    }
  };

  return (
    <Modal
      settings={{
        title: 'Replace parts or product',
        headerBackground: true,
        handleClose: () => setShowModal(false),
      }}
      kind="generic"
      isLoading={isSubmitting}
      className="max-h-[768px] w-full max-w-[1024px]"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => {
          return (
            <Form className="mt-5 flex flex-col gap-3 px-4">
              <Toggle
                label="Replace whole product"
                checked={replaceWholeProduct}
                onChange={() => handleToggleOnChange(props)}
              />
              {!replaceWholeProduct &&
                ids.map((id) => (
                  <PartsElement
                    key={id}
                    id={id}
                    handleDelete={() => {
                      handleDeleteElement(id);
                      setPartsValues((partsValues) =>
                        partsValues.filter((part) => part.id !== id)
                      );
                      props.setFieldValue(
                        'parts_used_for_repair',
                        partsValues.filter((part) => part.id !== id)
                      );
                    }}
                    onChange={(parts, errors) => {
                      if (errors && Object.keys(errors).length === 0) {
                        props.setFieldValue('parts_used_for_repair', parts);
                        props.setTouched({ parts_used_for_repair: undefined });
                      }
                    }}
                    setPartsValues={setPartsValues}
                    partsValues={partsValues}
                  />
                ))}
              <div className="ml-0 mr-auto">
                <ButtonIcon
                  iconLeft={false}
                  label="Add part"
                  disabled={replaceWholeProduct}
                  onClick={() => {
                    handleAddElement();
                  }}
                />
                {props.errors['parts_used_for_repair'] && (
                  <div className="font-nunito mt-2 flex items-start text-base text-red-500">
                    <ExclamationCircleIcon className="h-5 w-5" />
                    <span className="ml-1">
                      {props.errors['parts_used_for_repair'] as string}
                    </span>
                  </div>
                )}
              </div>
              <div className="mt-3">
                <Input
                  type="rich-text"
                  label="Comments to customer*"
                  name="comment_to_customer"
                  settings={{
                    id: 'comment_to_customer',
                    hint: '*required',
                  }}
                />
              </div>
              {!showInternalComment && (
                <div className="mt-4">
                  <Button
                    kind="outline"
                    type="button"
                    className="w-full"
                    onClick={() => setShowInternalComment(true)}
                  >
                    Add internal comment
                  </Button>
                </div>
              )}
              {showInternalComment && (
                <div>
                  <Input
                    type="rich-text"
                    label="Internal comment"
                    name="internal_comment"
                    settings={{
                      id: 'internal_comment',
                      placeholder: '',
                      allowSpecialCharacter: true,
                    }}
                  />
                </div>
              )}
              <div className="mt-8 flex gap-8">
                <Button kind="primary" type="submit" loading={isSubmitting}>
                  Send parts
                </Button>
                <Button
                  kind="outline_red"
                  type="submit"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default ReplacePartsOrProductModal;
