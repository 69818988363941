import {
  LIST_COUNTRIES,
  MANDRILL_TEMPLATES,
  useSendEmail,
} from '@rabbit/bizproc/react';
import {
  Button,
  Input,
  InputTypeSelectAddress,
  LoadingSpinner,
  Modal,
  getCountryByLabel,
} from '@rabbit/elements/shared-components';
import { Form, Formik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { CaseflowContext } from 'apps/sage/src/context/CaseflowContext';
import { getConsumerURL, useAppInfo } from 'apps/sage/src/utils/helpers';
import { toast } from 'react-toastify';
import { Address } from '@rabbit/data/types';
import { SelectAddressShape } from '@rabbit/elements/shared-types';
import { useTranslation } from 'react-i18next';

export interface AssignClaimToThirdPartyModalProps {}

interface FormValuesShape {
  repairer_business_name: string;
  repairer_name: string;
  repairer_contact_details: {
    contact_number: string;
    contact_number_e164: string;
    repairer_email: string;
  };
  repairer_address: Address;
  internal_comment: string;
  comment_to_customer: string;
}

const validationSchema = Yup.object().shape({
  repairer_business_name: Yup.string()
    .trim()
    .required("Business name can't be empty"),
  repairer_name: Yup.string().trim(),
  comment_to_customer: Yup.string()
    .trim()
    .required('Please enter a message')
    .test('remove-html-tags', 'please insert a message.', (value) => {
      const div = document.createElement('div');
      div.innerHTML = value || '';
      return !value || div.textContent?.trim() !== '';
    }),
  repairer_contact_details: Yup.object()
    .shape({
      contact_number: Yup.string().required(
        "Please enter repairer's contact number."
      ),
      repairer_email: Yup.string()
        .email('Please enter a valid email address')
        .required('Please enter a valid email address'),
    })
    .required("Please enter a repairer's details"),
  repairer_address: Yup.object()
    .shape({
      line1: Yup.string().required('Please enter an address'),
      town: Yup.string().required('Please enter a city / suburb'),
      state: Yup.string().required('Please enter a state / territory'),
      postcode: Yup.string().required('Please enter a post code'),
      country: Yup.string().required('Please enter a country'),
    })
    .required('Please enter a repairer address'),
  internal_comment: Yup.string().trim(),
});

export function AssignClaimToThirdPartyModal({}: AssignClaimToThirdPartyModalProps) {
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedAddress, setSelectedAddress] =
    useState<SelectAddressShape | null>({} as SelectAddressShape);
  const [showInternalComment, setShowInternalComment] = useState(false);
  const { SE_Sage_Notify_Authorised_Repairer } =
    useSendEmail();

  const formikRef = useRef(null) as any;

  const initialValues = {
    repairer_business_name: '',
    repairer_name: '',
    repairer_contact_details: {
      contact_number: '',
      contact_number_e164: '',
      repairer_email: '',
    },
    repairer_address: {
      postcode: '',
      line1: '',
      line2: '',
      town: '',
      state: '',
      country: appInfo.country ?? '',
    },
    internal_comment: '',
    comment_to_customer: '',
  };

  const {
    caseFacts,
    alterCaseFacts,
    alterCasePublicEmail,
    executeAction,
    moveSpotlight,
    setShowModal,
    operatingPersona,
    operatingPersonaSingleKey,
    caseId,
  } = useContext(CaseflowContext) || {};

  if (
    !setShowModal ||
    !executeAction ||
    !alterCaseFacts ||
    !alterCasePublicEmail ||
    !moveSpotlight ||
    !operatingPersonaSingleKey ||
    !operatingPersona ||
    !caseFacts ||
    !caseId
  )
    return <LoadingSpinner size={'xs'} />;

  const business_name = appInfo.name ?? '';

  const onSubmit = async (values: FormValuesShape) => {
    const {
      internal_comment,
      repairer_business_name,
      comment_to_customer,
      repairer_name,
      repairer_contact_details,
      repairer_address,
    } = values;
    setIsSubmitting(true);

    await moveSpotlight(operatingPersonaSingleKey);
    executeAction('assign_to_authorised_repairer');

    try {
      const factsToAlter = {
        ...(internal_comment && {
          internal_comment: {
            comment: internal_comment,
            author: operatingPersona,
          },
        }),
        repairer_business_name: repairer_business_name,
        comment_to_customer: comment_to_customer,
        repairer_name: repairer_name,
        repairer_contact_details: {
          contact_number:
            repairer_contact_details?.contact_number_e164 ??
            repairer_contact_details?.contact_number,
          repairer_email: repairer_contact_details?.repairer_email,
        },
        repairer_address: {
          line1: repairer_address?.line1,
          line2: repairer_address?.line2,
          town: repairer_address?.town,
          state: repairer_address?.state,
          postcode: repairer_address?.postcode,
          country: repairer_address?.country,
        },
      };

      const to = caseFacts?.consumer_email ?? '',
        from = appInfo.email_sender,
        template = MANDRILL_TEMPLATES.BODY_REPAIRER_ASSIGNED,
        business_name = appInfo.name ?? '',
        claim_id = caseId,
        first_name = caseFacts?.consumer_name ?? '',
        product_name = caseFacts.consumer_holding_name ?? '',
        preliminary_assessment =
          caseFacts?.preliminary_assessment?.toLowerCase() ?? '',
        repairer_email = `${repairer_contact_details?.repairer_email}`,
        repairer_phone_no = `${
          repairer_contact_details?.contact_number_e164 ??
          repairer_contact_details?.contact_number
        }`,
        subject =
          t('Your claim has been assigned to an authorised repairer by ') +
          business_name +
          ' - ' +
          product_name,
        link_to_claim = `${getConsumerURL()}/repairs/${caseId}`;

      if (Object.keys(factsToAlter).length > 0) {
        await alterCaseFacts(factsToAlter);
        await alterCasePublicEmail({
          context: 'repairer_assigned',
          from,
          to,
          subject,
          template,
          substitutions: {
            subject,
            business_name,
            claim_id,
            first_name,
            comment_to_customer,
            preliminary_assessment,
            repairer_business_name,
            repairer_email,
            repairer_phone_no,
            product_name,
            link_to_claim,
          },
          shallBeSentViaBackend: true
        });
      }

      //TODO: WIP, will need to be reviewed once the Mandrill template is updated - dc
      await SE_Sage_Notify_Authorised_Repairer(
        repairer_contact_details?.repairer_email ?? '',
        appInfo.email_sender,
        appInfo.email_main_template,
        appInfo.name,
        caseId,
        repairer_name,
        caseFacts.consumer_holding_name ?? ''
      );

      setShowModal(false);
      toast.success('Claim updated successfully.');
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong, please try again');
    }
  };

  useEffect(() => {
    if (!selectedAddress) {
      formikRef.current.resetForm();
      return;
    }
    if (formikRef && selectedAddress?.postal_code) {
      formikRef.current.setFieldValue(
        'repairer_address.line1',
        selectedAddress.line_1
      );
      formikRef.current.setFieldValue(
        'repairer_address.line2',
        selectedAddress.line_2
      );
      formikRef.current.setFieldValue(
        'repairer_address.town',
        selectedAddress.locality
      );
      formikRef.current.setFieldValue(
        'repairer_address.state',
        selectedAddress.province_name
      );
      formikRef.current.setFieldValue(
        'repairer_address.postcode',
        selectedAddress.postal_code
      );
      formikRef.current.setFieldValue(
        'repairer_address.country',
        getCountryByLabel(selectedAddress.country_name)?.value ?? ''
      );
    }
  }, [selectedAddress]);

  return (
    <Modal
      settings={{
        title: 'Assign to authorised repairer',
        headerBackground: true,
        handleClose: () => setShowModal(false),
      }}
      isLoading={isSubmitting}
      kind="generic"
      className="max-h-[768px] w-full max-w-[1024px] overflow-scroll"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        {({ errors, values }) => (
          <Form className="mt-5 flex flex-col gap-3 px-4">
            <div className="flex gap-3">
              <Input
                type="text"
                label="Business name*"
                name="repairer_business_name"
                settings={{
                  id: 'repairer_business_name',
                  hint: '*required',
                  placeholder: '',
                }}
              />
              <Input
                type="text"
                label="Repairer name"
                name="repairer_name"
                settings={{
                  id: 'repairer_name',
                  forceUpperCaseFirstLetter: true,
                }}
              />
            </div>
            <div className="flex gap-3">
              <Input
                type="email"
                label="Repairer email*"
                name="repairer_contact_details.repairer_email"
                settings={{
                  id: 'repairer_contact_details',
                  placeholder: '',
                  hint: '*required',
                }}
              />
              <Input
                type="phone"
                label="Contact number*"
                name="repairer_contact_details.contact_number"
                settings={{
                  hint: '*required',
                }}
              />
            </div>
            <label className="font-nunito text-base font-medium">
              Repairer address*
            </label>
            <Input
              type="select"
              label="Country*"
              name="repairer_address.country"
              settings={{
                options: LIST_COUNTRIES,
                hint: '*required',
              }}
            />
            <div>
              <InputTypeSelectAddress
                errors={errors['repairer_address']}
                onChange={(address) => setSelectedAddress(address)}
                country={values.repairer_address.country}
              />
            </div>
            <Input
              type="text"
              label="Address line*"
              name="repairer_address.line1"
              settings={{
                hint: '*required',
              }}
            />
            <Input
              type="text"
              label="Address line 2"
              name="repairer_address.line2"
              settings={{}}
            />
            <div className="flex gap-3">
              <Input
                type="text"
                label="Town/Suburb*"
                name="repairer_address.town"
                settings={{
                  hint: '*required',
                }}
              />
              <Input
                type="text"
                label="State/Territory*"
                name="repairer_address.state"
                settings={{
                  hint: '*required',
                }}
              />
            </div>
            <div className="flex gap-3">
              <Input
                type="text"
                label="Post code*"
                name="repairer_address.postcode"
                settings={{
                  id: 'postcode',
                  placeholder: '',
                  hint: '*required',
                }}
              />
            </div>
            <div className="flex-col gap-3">
              <Input
                type="rich-text"
                label="Comments to customer*"
                name="comment_to_customer"
                settings={{
                  id: 'comment_to_customer',
                  placeholder: '',
                  hint: '*required',
                }}
              />
              {!showInternalComment && (
                <div className="mt-4">
                  <Button
                    kind="outline"
                    type="button"
                    className="w-full"
                    onClick={() => setShowInternalComment(true)}
                  >
                    Add internal comment
                  </Button>
                </div>
              )}
              {showInternalComment && (
                <div className="mt-3">
                  <Input
                    type="rich-text"
                    label="Internal comment"
                    name="internal_comment"
                    settings={{
                      id: 'internal_comment',
                      placeholder: '',
                      allowSpecialCharacter: true,
                    }}
                  />
                </div>
              )}
            </div>
            <div className="mt-8 flex gap-8">
              <Button kind="primary" type="submit" loading={isSubmitting}>
                Assign to repairer
              </Button>
              <Button
                kind="outline_red"
                type="submit"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default AssignClaimToThirdPartyModal;
